<app-header1></app-header1>
<!--====== Breadcrumb part Start ======-->
    <section class="breadcrumb-section bg-img-c" style="background-image: url(assets/img/breadcrumb.jpg);">
        <div class="container">
            <div class="breadcrumb-text">
                <h1 class="page-title">FAQ</h1>
                <ul>
                    <li><a routerLink='/'>Home</a></li>
                    <li>Faq</li>
                </ul>
            </div>
        </div>
        <div class="breadcrumb-shapes">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
        </div>
    </section>
    <!--====== Breadcrumb part End ======-->

    <!--====== FAQ Section Start ======-->
    <section class="faq-section section-gap">
        <div class="container">
            <!-- FAQ Page Title -->
            <div class="row faq-page-title mb-60 align-items-center">
                <div class="col-lg-6">
                    <div class="section-title left-border">
                        <span class="title-tag">FAQ</span>
                        <h2 class="title">Frequently Asked Questions</h2>
                    </div>
                </div>
                <div class="col-lg-6">
                    <p>
                        But must explain to you how all this mistaken idea of denounc easure and praising pain was born
                        and I will give you a compl the system, and expound the actual teachings of the great explorer
                        the truth,
                    </p>
                </div>
            </div>

            <!-- FAQ LOOP -->
            <div class="accordion faq-loop grey-header" id="faqAccordion">
                <div class="card">
                    <div class="card-header">
                        <h6 class="collapsed" data-toggle="collapse" data-target="#collapseOne">
                            How To Create A Mobile App In Expo And Firebase Meet SmashingConf Live: Our New Interactive
                            Online Conference

                            <span class="icons">
                                <i class="far fa-plus"></i>
                            </span>
                        </h6>
                    </div>

                    <div id="collapseOne" class="collapse" data-parent="#faqAccordion">
                        <div class="card-body">
                            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium
                            voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati
                            cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id
                            est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam
                            libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minu
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header active-header">
                        <h6 class="collapsed" data-toggle="collapse" data-target="#collapseTwo">
                            Smashing Podcast Episode With Ben How Optimize How To Build A Vue Survey App Using Firebase
                            ?

                            <span class="icons">
                                <i class="far fa-minus"></i>
                            </span>
                        </h6>
                    </div>

                    <div id="collapseTwo" class="collapse show" data-parent="#faqAccordion">
                        <div class="card-body">
                            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium
                            voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati
                            cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id
                            est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam
                            libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minu
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <h6 class="collapsed" data-toggle="collapse" data-target="#collapseThree">
                            Learning Resources Challenging Online Workshops Setting TypeScript Modern React Projects
                            Using Webpack ?

                            <span class="icons">
                                <i class="far fa-plus"></i>
                            </span>
                        </h6>
                    </div>

                    <div id="collapseThree" class="collapse" data-parent="#faqAccordion">
                        <div class="card-body">
                            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium
                            voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati
                            cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id
                            est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam
                            libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minu
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <h6 class="collapsed" data-toggle="collapse" data-target="#collapseFour">
                            How To Build A Vue Survey App Using Firebase Authentication And Database Blessing Krofegha
                            wrote ?

                            <span class="icons">
                                <i class="far fa-plus"></i>
                            </span>
                        </h6>
                    </div>

                    <div id="collapseFour" class="collapse" data-parent="#faqAccordion">
                        <div class="card-body">
                            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium
                            voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati
                            cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id
                            est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam
                            libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minu
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <h6 class="collapsed" data-toggle="collapse" data-target="#collapseFive">
                            How To Build A Vue Survey App Using Firebase Authentication And Database Meet Sma Ove Online
                            Conference ?

                            <span class="icons">
                                <i class="far fa-plus"></i>
                            </span>
                        </h6>
                    </div>

                    <div id="collapseFive" class="collapse" data-parent="#faqAccordion">
                        <div class="card-body">
                            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium
                            voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati
                            cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id
                            est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam
                            libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minu
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <h6 class="collapsed" data-toggle="collapse" data-target="#collapseSix">
                            How To Build A Vue Survey App Using Firebase Authentication And Database Join Our New Online
                            ccessibility ?

                            <span class="icons">
                                <i class="far fa-plus"></i>
                            </span>
                        </h6>
                    </div>

                    <div id="collapseSix" class="collapse" data-parent="#faqAccordion">
                        <div class="card-body">
                            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium
                            voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati
                            cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id
                            est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam
                            libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minu
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <h6 class="collapsed" data-toggle="collapse" data-target="#collapseSeven">
                            How To Build A Vue Survey App Using Firebase Authentication And Database Using components ?

                            <span class="icons">
                                <i class="far fa-plus"></i>
                            </span>
                        </h6>
                    </div>

                    <div id="collapseSeven" class="collapse" data-parent="#faqAccordion">
                        <div class="card-body">
                            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium
                            voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati
                            cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id
                            est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam
                            libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minu
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <h6 class="collapsed" data-toggle="collapse" data-target="#collapseEight">
                            How To Build A Vue Survey App Using Firebase Authentication And Interactive Online
                            Conference ?

                            <span class="icons">
                                <i class="far fa-plus"></i>
                            </span>
                        </h6>
                    </div>

                    <div id="collapseEight" class="collapse" data-parent="#faqAccordion">
                        <div class="card-body">
                            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium
                            voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati
                            cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id
                            est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam
                            libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minu
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <h6 class="collapsed" data-toggle="collapse" data-target="#collapseNine">
                            How To Build A Vue Survey App Using Firebase Authentication And Database Join Our New Online
                            ?

                            <span class="icons">
                                <i class="far fa-plus"></i>
                            </span>
                        </h6>
                    </div>

                    <div id="collapseNine" class="collapse" data-parent="#faqAccordion">
                        <div class="card-body">
                            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium
                            voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati
                            cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id
                            est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam
                            libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minu
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <h6 class="collapsed" data-toggle="collapse" data-target="#collapseTen">
                            How To Build A Vue Survey App Using Firebase Authentication And In React Apps Using
                            components ?

                            <span class="icons">
                                <i class="far fa-plus"></i>
                            </span>
                        </h6>
                    </div>

                    <div id="collapseTen" class="collapse" data-parent="#faqAccordion">
                        <div class="card-body">
                            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium
                            voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati
                            cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id
                            est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam
                            libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minu
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Faq LOOP -->
        </div>
    </section>
    <!--====== FAQ Section End ======-->

    <!--====== Contact Section start ======-->
    <section class="contact-section with-illustration-img grey-bg section-gap">
        <div class="container">
            <div class="row no-gutters justify-content-end">
                <div class="col-lg-8">
                    <div class="contact-form">
                        <div class="section-title left-border mb-40">
                            <span class="title-tag">Drop A Message</span>
                            <h2 class="title">Don’t Hesited To Contact Us</h2>
                        </div>

                        <form action="#">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="input-group mb-30">
                                        <input type="text" placeholder="Your Full Name">
                                        <span class="icon"><i class="far fa-user-circle"></i></span>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="input-group mb-30">
                                        <input type="email" placeholder="Your Email Address">
                                        <span class="icon"><i class="far fa-envelope-open"></i></span>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="input-group select mb-30">
                                        <select>
                                            <option value="subject" selected>Subject</option>
                                            <option value="1" selected>Creative Idea</option>
                                            <option value="2" selected>Relationship</option>
                                            <option value="3" selected>Business Strategy</option>
                                            <option value="4" selected>Productivity</option>
                                            <option value="5" selected>It Solutions</option>
                                            <option value="5" selected>Product Design</option>
                                        </select>
                                        <span class="icon"><i class="far fa-angle-down"></i></span>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="input-group textarea mb-30">
                                        <textarea placeholder="Write Message"></textarea>
                                        <span class="icon"><i class="far fa-pencil"></i></span>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button type="button" class="main-btn">Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="illustration-img">
            <img src="assets/img/illustration/man-with-earth.png" alt="Image">
        </div>
    </section>
    <!--====== Contact Section start ======-->

    <!--====== Client Area Start ======-->
    <section class="client-section">
        <div class="container">
            <div class="client-slider section-gap">
                <div class="row align-items-center justify-content-between" id="clientSlider">
                    <div class="col">
                        <a routerLink='/FAQ' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/FAQ' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/FAQ' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/FAQ' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/FAQ' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/FAQ' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/FAQ' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/FAQ' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/FAQ' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/FAQ' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>