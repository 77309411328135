<footer>
		<div class="container">
			<div class="footer-widget">
				<div class="row">
					<div class="col-lg-4 col-sm-5 order-1">
						<div class="widget site-info-widget">
							<div class="footer-logo">
								<img src="assets/img/logo-2.png" alt="Finsa">
							</div>
							<p>Power of choice is untrammelled & when nothing prevents our being able</p>
							<ul class="social-links">
								<li><a href="javascript:void(0)"><i class="fab fa-facebook-f"></i></a></li>
								<li><a href="javascript:void(0)"><i class="fab fa-twitter"></i></a></li>
								<li><a href="javascript:void(0)"><i class="fab fa-instagram"></i></a></li>
								<li><a href="javascript:void(0)"><i class="fab fa-behance"></i></a></li>
								<li><a href="javascript:void(0)"><i class="fab fa-dribbble"></i></a></li>
							</ul>
						</div>
					</div>
					<div class="col-lg-8 col-sm-7 order-2">
						<div class="widget newsletter-widget">
							<h4 class="widget-title">Subscribe Our Newsletters</h4>
							<div class="newsletter-form">
								<form action="#">
									<input type="email" placeholder="Enter Your Email">
									<button type="button" class="main-btn">Subscribe Now</button>
								</form>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-sm-6 order-3">
						<div class="widget nav-widget">
							<h4 class="widget-title">Quick Links</h4>
							<ul>
								<li><a href="javascript:void(0)">Company History</a></li>
								<li><a href="javascript:void(0)">Latest News & Blog</a></li>
								<li><a href="javascript:void(0)">Popular Services</a></li>
								<li><a href="javascript:void(0)">Business & Consulting</a></li>
								<li><a href="javascript:void(0)">Financial Planning</a></li>
							</ul>
						</div>
					</div>
					<div class="col-lg-5 order-lg-4 order-5">
						<div class="row">
							<div class="col-lg-6 col-sm-6">
								<div class="widget nav-widget">
									<h4 class="widget-title">Company</h4>
									<ul>
										<li><a href="javascript:void(0)">About Comapny</a></li>
										<li><a href="javascript:void(0)">World Wide Clients</a></li>
										<li><a href="javascript:void(0)">Happy People’s</a></li>
										<li><a href="javascript:void(0)">Winning Awards</a></li>
										<li><a href="javascript:void(0)">Company Statics</a></li>
									</ul>
								</div>
							</div>
							<div class="col-lg-6 col-sm-6">
								<div class="widget contact-widget">
									<h4 class="widget-title">Contact Us</h4>
									<p>Untrammelled & nothing preven our being able</p>
									<ul class="contact-infos">
										<li>
											<a href="tel:+0123456789">
												<i class="far fa-phone"></i>
												+012 (345) 6789
											</a>
										</li>
										<li>
											<a href="mailto:support@gmail.com">
												<i class="far fa-envelope-open"></i>
												support@gmail.com
											</a>
										</li>
										<li> <i class="far fa-map-marker-alt"></i> Broklyn Street USA</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 order-lg-5 order-4">
						<div class="widget insta-feed-widget">
							<h4 class="widget-title">Follow Instagram</h4>
							<div class="insta-images">
								<div class="insta-img" style="background-image: url(assets/img/instagram/01.jpg);">
									<a href="javascript:void(0)">
										<i class="fab fa-instagram"></i>
									</a>
								</div>
								<div class="insta-img" style="background-image: url(assets/img/instagram/02.jpg);">
									<a href="javascript:void(0)">
										<i class="fab fa-instagram"></i>
									</a>
								</div>
								<div class="insta-img" style="background-image: url(assets/img/instagram/03.jpg);">
									<a href="javascript:void(0)">
										<i class="fab fa-instagram"></i>
									</a>
								</div>
								<div class="insta-img" style="background-image: url(assets/img/instagram/04.jpg);">
									<a href="javascript:void(0)">
										<i class="fab fa-instagram"></i>
									</a>
								</div>
								<div class="insta-img" style="background-image: url(assets/img/instagram/05.jpg);">
									<a href="javascript:void(0)">
										<i class="fab fa-instagram"></i>
									</a>
								</div>
								<div class="insta-img" style="background-image: url(assets/img/instagram/06.jpg);">
									<a href="javascript:void(0)">
										<i class="fab fa-instagram"></i>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="footer-copyright">
				<p class="copyright-text">
					<span>Copy@ <a href="javascript:void(0)">Finsa</a>. Example -2020</span>
					<span>All Right Reserved Design By Example</span>
				</p>

				<a href="javascript:void(0)" class="back-to-top"><i class="far fa-angle-up"></i></a>
			</div>
		</div>

		<!-- Lines -->
		<img src="assets/img/lines/01.png" alt="line-shape" class="line-one">
		<img src="assets/img/lines/02.png" alt="line-shape" class="line-two">
	</footer>