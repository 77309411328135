import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header1',
  templateUrl: './header1.component.html',
  styleUrls: ['./header1.component.css']
})
export class Header1Component implements OnInit {

  mostrarHead: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  toggleDiv() {
    this.mostrarHead = !this.mostrarHead;
  }

}
