<app-header2></app-header2>

<app-video-slider></app-video-slider>

	<!--====== About Section start ======-->
	<section class="about-section about-illustration-img section-gap">
		<div class="container">
			<div class="illustration-img">
				<img src="assets/img/illustration/01.png" alt="Image">
			</div>
			<div class="row no-gutters justify-content-lg-end justify-content-center">
				<div class="col-lg-6 col-md-10">
					<div class="about-text">
						<div class="section-title left-border mb-40">
							<span class="title-tag">About Us</span>
							<h2 class="title">Professional Business <br> Guidance Agency </h2>
						</div>
						<p class="mb-25">
							Sedut perspiciatis unde omnis iste natus error sit voluptat em accusantium doloremq
							laudantium, totam raperiaeaque ipsa quae ab illo inventore veritatis et quasi
						</p>
						<p>
							Doloremque laudantium, totam raperiaeaque ipsa quae ab illo inventore veritatis et quasi
						</p>
						<ul class="about-list">
							<li> <i class="far fa-check"></i> Business & Consulting Agency</li>
							<li> <i class="far fa-check"></i> Awards Winning Business Comapny</li>
						</ul>
						<a routerLink='/about-us' class="main-btn">Learn More</a>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!--====== About Section end ======-->

	<!--====== Service Part Start ======-->
	<section class="service-section shape-style-one section-gap grey-bg">
		<div class="container">
			<!-- Section Title -->
			<div class="section-title text-center both-border mb-30">
				<span class="title-tag">Comany Services</span>
				<h2 class="title">We Provide Most Exclusive <br> Service For Business</h2>
			</div>
			<!-- Services Boxes -->
			<div class="row service-boxes justify-content-center">
				<div class="col-lg-4 col-md-6 col-sm-8 col-10 col-tiny-12 wow fadeInLeft" data-wow-duration="1500ms"
					data-wow-delay="400ms">
					<div class="service-box text-center">
						<div class="icon">
							<img src="assets/img/icons/01.png" alt="Icon">
						</div>
						<h3><a routerLink='/service-detail'>Creative Idea</a></h3>
						<p>Sed ut perspiciatis unde omnis iste natus erro volupta</p>
						<a routerLink='/service-detail' class="service-link">
							<i class="fal fa-long-arrow-right"></i>
						</a>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 col-sm-8 col-10 col-tiny-12 wow fadeInUp" data-wow-duration="1500ms"
					data-wow-delay="600ms">
					<div class="service-box text-center">
						<div class="icon">
							<img src="assets/img/icons/02.png" alt="Icon">
						</div>
						<h3><a routerLink='/service-detail'>Business Strategy</a></h3>
						<p>Sed ut perspiciatis unde omnis iste natus erro volupta</p>
						<a routerLink='/service-detail' class="service-link">
							<i class="fal fa-long-arrow-right"></i>
						</a>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 col-sm-8 col-10 col-tiny-12 wow fadeInRight" data-wow-duration="1500ms"
					data-wow-delay="800ms">
					<div class="service-box text-center">
						<div class="icon">
							<img src="assets/img/icons/03.png" alt="Icon">
						</div>
						<h3><a routerLink='/service-detail'>Relationship Buildup</a></h3>
						<p>Sed ut perspiciatis unde omnis iste natus erro volupta</p>
						<a routerLink='/service-detail' class="service-link">
							<i class="fal fa-long-arrow-right"></i>
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="dots-line">
			<img src="assets/img/lines/07.png" alt="Image">
		</div>
	</section>
	<!--====== Service Part End ======-->

	<!--====== Video Start ======-->
	<section class="video-section bg-img-c section-gap" style="background-image: url(assets/img/video-bg/video-1.jpg);">
		<div class="container">
			<div class="row align-items-center justify-content-between">
				<div class="col-xl-7 col-lg-8 col-md-10 order-2 order-md-1">
					<div class="video-text">
						<div class="section-title left-border mb-30">
							<span class="title-tag">Watch Videos</span>
							<h2 class="title">
								Exclusive Video Presentation <br> About Recent Project
							</h2>
						</div>
						<p>
							Ranim ad minima veniam, quis nostrum exercitationem ullam corpsuscipit
							laboriosam, nisi ut aliquid exea commodi consequa turuis autem
						</p>
						<a routerLink='/' class="main-btn">Need Any Project</a>
					</div>
				</div>
				<div class="col-lg-3 col-lg-4 col-md-2 order-1 order-md-2">
					<div class="video-btn text-md-center wow fadeInUp" data-wow-duration="1500ms"
						data-wow-delay="400ms">
						<a routerLink='/' class="play-btn popup-video">
							<img src="assets/img/icons/play.svg" alt="">
							<i class="fas fa-play"></i>
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="line-shape">
			<img src="assets/img/lines/08.png" alt="Line">
		</div>
	</section>
	<!--====== Video end ======-->

	<!--====== Feature Part start ======-->
	<section class="feature-section section-gap">
		<div class="container">
			<div class="section-title text-center both-border mb-50">
				<span class="title-tag"> Our Core Features </span>
				<h2 class="title">We Are Specialist For Many <br> Business Solutions</h2>
			</div>
			<!-- Feature boxes -->
			<div class="feature-boxes row justify-content-center">
				<div class="col-lg-4 col-md-6 col-10 col-tiny-12">
					<div class="feature-box">
						<div class="feature-bg bg-img-c" style="background-image: url(assets/img/feature/01.jpg);">
						</div>
						<div class="feature-desc">
							<a routerLink='/portfolio-detail' class="feature-link"><i class="fal fa-long-arrow-right"></i></a>
							<h4>Free Consulting</h4>
							<p>Join us for consultatoins</p>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 col-10 col-tiny-12">
					<div class="feature-box">
						<div class="feature-bg bg-img-c" style="background-image: url(assets/img/feature/02.jpg);">
						</div>
						<div class="feature-desc">
							<a routerLink='/portfolio-detail' class="feature-link"><i class="fal fa-long-arrow-right"></i></a>
							<h4>Business Growth</h4>
							<p>Join us for consultatoins</p>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 col-10 col-tiny-12">
					<div class="feature-box">
						<div class="feature-bg bg-img-c" style="background-image: url(assets/img/feature/03.jpg);">
						</div>
						<div class="feature-desc">
							<a routerLink='/portfolio-detail' class="feature-link"><i class="fal fa-long-arrow-right"></i></a>
							<h4>IT Consulting</h4>
							<p>Join us for consultatoins</p>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 col-10 col-tiny-12">
					<div class="feature-box">
						<div class="feature-bg bg-img-c" style="background-image: url(assets/img/feature/04.jpg);">
						</div>
						<div class="feature-desc">
							<a routerLink='/portfolio-detail' class="feature-link"><i class="fal fa-long-arrow-right"></i></a>
							<h4>Investment Plan</h4>
							<p>Join us for consultatoins</p>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 col-10 col-tiny-12">
					<div class="feature-box">
						<div class="feature-bg bg-img-c" style="background-image: url(assets/img/feature/05.jpg);">
						</div>
						<div class="feature-desc">
							<a routerLink='/portfolio-detail' class="feature-link"><i class="fal fa-long-arrow-right"></i></a>
							<h4>Financial Planning</h4>
							<p>Join us for consultatoins</p>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 col-10 col-tiny-12">
					<div class="feature-box">
						<div class="feature-bg bg-img-c" style="background-image: url(assets/img/feature/06.jpg);">
						</div>
						<div class="feature-desc">
							<a routerLink='/portfolio-detail' class="feature-link"><i class="fal fa-long-arrow-right"></i></a>
							<h4>Relationship</h4>
							<p>Join us for consultatoins</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!--====== Feature Part end ======-->

	<!--====== Why Choose Us Part Start ======-->
	<section class="wcu-section box-style">
		<div class="container">
			<div class="wcu-inner">
				<div class="row align-items-center justify-content-center">
					<div class="col-lg-6">
						<div class="wcu-image text-center text-lg-left wow fadeInUp" data-wow-duration="1500ms"
							data-wow-delay="400ms">
							<img src="assets/img/illustration/02.png" alt="Image">
						</div>
					</div>
					<div class="col-lg-6 col-md-10">
						<div class="wcu-text">
							<div class="section-title left-border mb-40">
								<span class="title-tag">What We Do</span>
								<h2 class="title">Why Choose Finsa <br> Business Agency</h2>
							</div>
							<p>
								Sedut perspiciatis unde omnis iste natus evoluptat em accusantium doloremque laudantium
							</p>
							<ul class="wcu-list">
								<li>
									<i class="far fa-check-circle"></i> Specialist Company Advisor Team
								</li>
								<li>
									<i class="far fa-check-circle"></i> Understanding Machines OpenS tandard

								</li>
								<li>
									<i class="far fa-check-circle"></i> Can Data Visualization Improve Mobile
								</li>
							</ul>

							<a routerLink='/contact-us' class="main-btn main-btn-4">Join With Us</a>
						</div>
					</div>
				</div>
				<img src="assets/img/lines/03.png" alt="shape" class="line-shape-one">
				<img src="assets/img/lines/04.png" alt="shape" class="line-shape-two">
			</div>
		</div>
	</section>
	<!--====== Why Choose Us Part End ======-->

	<!--====== Fact Part Start ======-->
	<section class="fact-section grey-bg">
		<div class="container">
			<div class="fact-boxes row justify-content-between align-items-center">
				<div class="col-lg-3 col-6">
					<div class="fact-box text-center mb-40">
						<div class="icon">
							<i class="flaticon-graphic"></i>
						</div>
						<h2 class="counter">3568</h2>
						<p class="title">Project Compalte</p>
					</div>
				</div>
				<div class="col-lg-3 col-6">
					<div class="fact-box text-center mb-40">
						<div class="icon">
							<i class="flaticon-plan"></i>
						</div>
						<h2 class="counter">7859</h2>
						<p class="title">Satisfied Clients</p>
					</div>
				</div>
				<div class="col-lg-3 col-6">
					<div class="fact-box text-center mb-40">
						<div class="icon">
							<i class="flaticon-target-1"></i>
						</div>
						<h2 class="counter">6352</h2>
						<p class="title">International Awards</p>
					</div>
				</div>
				<div class="col-lg-3 col-6">
					<div class="fact-box text-center mb-40">
						<div class="icon">
							<i class="flaticon-teamwork"></i>
						</div>
						<h2 class="counter">7856</h2>
						<p class="title">Expert Team Members</p>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!--====== Fact Part End ======-->

	<!--====== Team Section Start ======-->
	<section class="team-section section-gap">
		<div class="container">
			<!-- Section Title -->
			<div class="section-title mb-40 both-border text-center">
				<span class="title-tag">Meet Our Advisors</span>
				<h2 class="title">We Have An Exclusive <br> Team Member</h2>
			</div>

			<!-- Team Boxes -->
			<div class="row team-members" id="teamSliderOne">
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/01.jpg" alt="TeamMember">
								<div class="social-icons">
									<a routerLink='/'>
										<i class="fab fa-facebook-f"></i>
									</a>
									<a routerLink='/'>
										<i class="fab fa-twitter"></i>
									</a>
									<a routerLink='/'>
										<i class="fab fa-behance"></i>
									</a>
									<a routerLink='/'>
										<i class="fab fa-instagram"></i>
									</a>
								</div>
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/team-detail'>John N. Holmes</a></h3>
							<span class="pro">Business Developer</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/02.jpg" alt="TeamMember">
								<div class="social-icons">
									<a routerLink='/'>
										<i class="fab fa-facebook-f"></i>
									</a>
									<a routerLink='/'>
										<i class="fab fa-twitter"></i>
									</a>
									<a routerLink='/'>
										<i class="fab fa-behance"></i>
									</a>
									<a routerLink='/'>
										<i class="fab fa-instagram"></i>
									</a>
								</div>
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/team-detail'>Kenneth A. Spencer</a></h3>
							<span class="pro">Business Developer</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/03.jpg" alt="TeamMember">
								<div class="social-icons">
									<a routerLink='/'>
										<i class="fab fa-facebook-f"></i>
									</a>
									<a routerLink='/'>
										<i class="fab fa-twitter"></i>
									</a>
									<a routerLink='/'>
										<i class="fab fa-behance"></i>
									</a>
									<a routerLink='/'>
										<i class="fab fa-instagram"></i>
									</a>
								</div>
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/team-detail'>David K. Marble</a></h3>
							<span class="pro">Business Developer</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/04.jpg" alt="TeamMember">
								<div class="social-icons">
									<a routerLink='/'>
										<i class="fab fa-facebook-f"></i>
									</a>
									<a routerLink='/'>
										<i class="fab fa-twitter"></i>
									</a>
									<a routerLink='/'>
										<i class="fab fa-behance"></i>
									</a>
									<a routerLink='/'>
										<i class="fab fa-instagram"></i>
									</a>
								</div>
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a routerLink='/team-detail'>Raymond Grimes</a></h3>
							<span class="pro">Business Developer</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!--====== Team Section End ======-->

	<!--====== Skill Section Start ======-->
	<section class="skill-section">
		<div class="container">
			<div class="row align-items-center justify-content-center">
				<div class="col-lg-6 col-md-10">
					<!-- Skill Text Block -->
					<div class="skill-text">
						<div class="section-title mb-40 left-border">
							<span class="title-tag">Company Skills</span>
							<h2 class="title">We Have Experience <br> For Business Planning</h2>
						</div>
						<p>
							Sedut perspiciatis unde omnis iste natus error sit voluptat em accusantium doloremque
							laudantium, totam raperiaeaque ipsa quae ab illo inventore veritatis et quasi
						</p>
						<p>
							But I must explain to you how all this mistaken idenouncing pleasure and praising pain was
							born and I will give completey account of the system, and expound the actual teachings of
							the great explorer of the truth, the master-builder of human happiness one rejects,
							dislikes, or avoid
						</p>

						<a routerLink='/about-us' class="main-btn">Learn More</a>
					</div>
				</div>
				<div class="col-lg-6 col-md-10">
					<div class="piechart-boxes">
						<div class="chart-box">
							<div class="chart" data-percent="25">
								<i class="flaticon-presentation"></i>
							</div>
							<h4 class="title">Business Strategy</h4>
						</div>
						<div class="chart-box">
							<div class="chart" data-percent="40">
								<i class="flaticon-money-bags"></i>
							</div>
							<h4 class="title">Financial Planing</h4>
						</div>
						<div class="chart-box">
							<div class="chart" data-percent="75">
								<i class="flaticon-invest"></i>
							</div>
							<h4 class="title">Marketing Startegy</h4>
						</div>
						<div class="chart-box">
							<div class="chart" data-percent="80">
								<i class="flaticon-connector"></i>
							</div>
							<h4 class="title">Relationship Buildup</h4>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!--====== Skill Section End ======-->

	<!--====== Testimonials part start ======-->
	<section class="testimonial-section grey-bg">
		<div class="container">
			<div class="row justify-content-center justify-content-lg-start">
				<div class="col-lg-6 col-md-10 offset-lg-5">
					<div class="section-title left-border">
						<span class="title-tag">Meet Our Advisors</span>
						<h2 class="title">We Have An Exclusive <br> Team Member</h2>
					</div>
					<div class="testimonial-items" id="testimonialSliderOne">
						<div class="testimonial-item">
							<div class="content">
								<p>
									<span class="quote-top">
										<i class="fas fa-quote-left"></i>
									</span>
									On the other hand we denounce with righteou indg ation and dislike men who are so
									beguiled and demoraliz ed by the charms of pleasure of the moment
									<br>
									Dislike men who are so beguiled and demoraliz worlds ed by the charms of pleasure of
									the moment
									<span class="quote-bottom">
										<i class="fas fa-quote-right"></i>
									</span>
								</p>

							</div>
							<div class="author">
								<div class="thumb">
									<img src="assets/img/testimonial/author.png" alt="img">
								</div>
								<div class="desc">
									<h4>Richard E. Harrisona</h4>
									<span>CEO & Founder</span>
								</div>
							</div>
						</div>
						<div class="testimonial-item">
							<div class="content">
								<p>
									<span class="quote-top">
										<i class="fas fa-quote-left"></i>
									</span>
									On the other hand we denounce with righteou indg ation and dislike men who are so
									beguiled and demoraliz ed by the charms of pleasure of the moment
									<br>
									Dislike men who are so beguiled and demoraliz worlds ed by the charms of pleasure of
									the moment
									<span class="quote-bottom">
										<i class="fas fa-quote-right"></i>
									</span>
								</p>

							</div>
							<div class="author">
								<div class="thumb">
									<img src="assets/img/testimonial/author.png" alt="img">
								</div>
								<div class="desc">
									<h4>Richard E. Harrisona</h4>
									<span>CEO & Founder</span>
								</div>
							</div>
						</div>
						<div class="testimonial-item">
							<div class="content">
								<p>
									<span class="quote-top">
										<i class="fas fa-quote-left"></i>
									</span>
									On the other hand we denounce with righteou indg ation and dislike men who are so
									beguiled and demoraliz ed by the charms of pleasure of the moment
									<br>
									Dislike men who are so beguiled and demoraliz worlds ed by the charms of pleasure of
									the moment
									<span class="quote-bottom">
										<i class="fas fa-quote-right"></i>
									</span>
								</p>

							</div>
							<div class="author">
								<div class="thumb">
									<img src="assets/img/testimonial/author.png" alt="img">
								</div>
								<div class="desc">
									<h4>Richard E. Harrisona</h4>
									<span>CEO & Founder</span>
								</div>
							</div>
						</div>
					</div>
					<div class="testimonial-arrows row"></div>
				</div>
			</div>
		</div>
		<!-- Testimonials img -->
		<div class="testimonial-img">
			<img src="assets/img/testimonial/testimonial.png" alt="testimonial">
		</div>
	</section>
	<!--====== Testimonials part end ======-->

	<!--====== Client Area Start ======-->
	<section class="client-section">
		<div class="container">
			<div class="client-slider section-gap line-bottom">
				<div class="row align-items-center justify-content-between" id="clientSlider">
					<div class="col">
						<a routerLink='/' class="client-img d-block text-center">
							<img src="assets/img/clients/01.png" alt="">
						</a>
					</div>
					<div class="col">
						<a routerLink='/' class="client-img d-block text-center">
							<img src="assets/img/clients/02.png" alt="">
						</a>
					</div>
					<div class="col">
						<a routerLink='/' class="client-img d-block text-center">
							<img src="assets/img/clients/03.png" alt="">
						</a>
					</div>
					<div class="col">
						<a routerLink='/' class="client-img d-block text-center">
							<img src="assets/img/clients/04.png" alt="">
						</a>
					</div>
					<div class="col">
						<a routerLink='/' class="client-img d-block text-center">
							<img src="assets/img/clients/05.png" alt="">
						</a>
					</div>
					<div class="col">
						<a routerLink='/' class="client-img d-block text-center">
							<img src="assets/img/clients/01.png" alt="">
						</a>
					</div>
					<div class="col">
						<a routerLink='/' class="client-img d-block text-center">
							<img src="assets/img/clients/02.png" alt="">
						</a>
					</div>
					<div class="col">
						<a routerLink='/' class="client-img d-block text-center">
							<img src="assets/img/clients/03.png" alt="">
						</a>
					</div>
					<div class="col">
						<a routerLink='/' class="client-img d-block text-center">
							<img src="assets/img/clients/04.png" alt="">
						</a>
					</div>
					<div class="col">
						<a routerLink='/' class="client-img d-block text-center">
							<img src="assets/img/clients/05.png" alt="">
						</a>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!--====== Client Area End ======-->

	<!--====== Latest Post Start ======-->
	<section class="latest-post-section section-gap">
		<div class="container">
			<div class="row align-items-center justify-content-center">
				<div class="col-lg-6 col-md-8 col-10 col-tiny-12">
					<div class="section-title left-border">
						<span class="title-tag">Important Articles</span>
						<h2 class="title">Something Know Our <br> Latest News & Blog</h2>
					</div>
				</div>
				<div class="col-lg-6 col-md-4 col-10 col-tiny-12">
					<div class="text-md-right mt-30 mt-md-0">
						<a routerLink='/blog-grid' class="main-btn">View All News</a>
					</div>
				</div>
			</div>
			<div class="latest-post-loop row mt-50 justify-content-center">
				<div class="col-lg-4 col-md-6 col-10 col-tiny-12 wow fadeInLeft" data-wow-duration="1500ms"
					data-wow-delay="400ms">
					<div class="latest-post-box">
						<div class="post-thumb-wrap">
							<div class="post-thumb bg-img-c"
								style="background-image: url(assets/img/latest-post/01.jpg);">
							</div>
						</div>
						<div class="post-desc">
							<span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span>
							<h3 class="title">
								<a routerLink='/blog-detail'>
									How Performance Visiblety With GitLab CI And Hood
								</a>
							</h3>
							<p>
								Sedut perspiciatis unde omnis nat erroroluptat accusantium laudantim totam
								raperiaeaqupsa quae
							</p>
							<a routerLink='/blog-detail' class="post-link">
								Learn More <i class="far fa-long-arrow-right"></i>
							</a>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 col-10 col-tiny-12 wow fadeInUp" data-wow-duration="1500ms"
					data-wow-delay="600ms">
					<div class="latest-post-box">
						<div class="post-thumb-wrap">
							<div class="post-thumb bg-img-c"
								style="background-image: url(assets/img/latest-post/02.jpg);">
							</div>
						</div>
						<div class="post-desc">
							<span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span>
							<h3 class="title">
								<a routerLink='/blog-detail'>
									Inspired Design Decisions With Max Huber Turne
								</a>
							</h3>
							<p>
								Vero eos et accusamus et iustoys odio dignissimos ducimu blanditiis praesentium
								voluptatum
							</p>
							<a routerLink='/blog-detail' class="post-link">
								Learn More <i class="far fa-long-arrow-right"></i>
							</a>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 col-10 col-tiny-12 wow fadeInRight" data-wow-duration="1500ms"
					data-wow-delay="800ms">
					<div class="latest-post-box">
						<div class="post-thumb-wrap">
							<div class="post-thumb bg-img-c"
								style="background-image: url(assets/img/latest-post/03.jpg);">
							</div>
						</div>
						<div class="post-desc">
							<span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span>
							<h3 class="title">
								<a routerLink='/blog-detail'>
									Understanding Machines An Open Standard For
								</a>
							</h3>
							<p>
								Power of choice is untrammelled and when nothing prevents beings able to do
								what we like best
							</p>
							<a routerLink='/blog-detail' class="post-link">
								Learn More <i class="far fa-long-arrow-right"></i>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!--====== Latest Post Start ======-->
	<app-footer1></app-footer1>