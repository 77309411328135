<app-header1></app-header1>
<!--====== Breadcrumb part Start ======-->
    <section class="breadcrumb-section bg-img-c" style="background-image: url(assets/img/breadcrumb.jpg);">
        <div class="container">
            <div class="breadcrumb-text">
                <h1 class="page-title">Careers</h1>
                <ul>
                    <li><a routerLink='/'>Home</a></li>
                    <li>Careers</li>
                </ul>
            </div>
        </div>
        <div class="breadcrumb-shapes">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
        </div>
    </section>
    <!--====== Breadcrumb part End ======-->

    <!--====== Job Category Start======-->
    <section class="job-category-section section-gap">
        <div class="container">
            <div class="job-categories">
                <div class="single-cat wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                    <a routerLink='/career'>
                        <i class="flaticon-computer"></i>
                        <span>It Solutions</span>
                    </a>
                </div>
                <div class="single-cat wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="500ms">
                    <a routerLink='/career'>
                        <i class="flaticon-creative"></i>
                        <span>It Consulting</span>
                    </a>
                </div>
                <div class="single-cat wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                    <a routerLink='/career'>
                        <i class="flaticon-idea"></i>
                        <span>Product Design</span>
                    </a>
                </div>
                <div class="single-cat wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="700ms">
                    <a routerLink='/career'>
                        <i class="flaticon-fingerprint"></i>
                        <span>Server Security</span>
                    </a>
                </div>
                <div class="single-cat wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="800ms">
                    <a routerLink='/career'>
                        <i class="flaticon-shield"></i>
                        <span>It Management</span>
                    </a>
                </div>
                <div class="single-cat wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="900ms">
                    <a routerLink='/career'>
                        <i class="flaticon-training"></i>
                        <span>UX/UI Strategy</span>
                    </a>
                </div>
                <div class="single-cat wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="1s">
                    <a routerLink='/career'>
                        <i class="flaticon-analysis"></i>
                        <span>IT Marketing</span>
                    </a>
                </div>
                <div class="single-cat wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="1.1s">
                    <a routerLink='/career'>
                        <i class="flaticon-piggy-bank"></i>
                        <span>Bank & Finance</span>
                    </a>
                </div>
            </div>
        </div>
    </section>
    <!--====== Job Category End======-->

    <!--====== Job List Start======-->
    <section class="job-list-section section-gap grey-bg">
        <div class="container">
            <div class="job-lists">
                <div class="single-job">
                    <h4 class="title">Web Designer <span class="job-time">(Full Time)</span></h4>
                    <p>
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusa ntium doloremque
                        laudantium totam rem aperiam
                    </p>
                    <a routerLink='/career' class="apply-btn">apply now <i class="far fa-long-arrow-right"></i></a>
                </div>
                <div class="single-job">
                    <h4 class="title">Web Developer <span class="job-time">(Full Time)</span></h4>
                    <p>
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusa ntium doloremque
                        laudantium totam rem aperiam
                    </p>
                    <a routerLink='/career' class="apply-btn">apply now <i class="far fa-long-arrow-right"></i></a>
                </div>
                <div class="single-job">
                    <h4 class="title">SEO Optimization <span class="job-time">(Part Time)</span></h4>
                    <p>
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusa ntium doloremque
                        laudantium totam rem aperiam
                    </p>
                    <a routerLink='/career' class="apply-btn">apply now <i class="far fa-long-arrow-right"></i></a>
                </div>
                <div class="single-job">
                    <h4 class="title">IT Marketing <span class="job-time">(Full Time)</span></h4>
                    <p>
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusa ntium doloremque
                        laudantium totam rem aperiam
                    </p>
                    <a routerLink='/career' class="apply-btn">apply now <i class="far fa-long-arrow-right"></i></a>
                </div>
                <div class="single-job">
                    <h4 class="title">IT Consultant <span class="job-time">(Part Time)</span></h4>
                    <p>
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusa ntium doloremque
                        laudantium totam rem aperiam
                    </p>
                    <a routerLink='/career' class="apply-btn">apply now <i class="far fa-long-arrow-right"></i></a>
                </div>
                <div class="single-job">
                    <h4 class="title">Computer Engineer <span class="job-time">(Full Time)</span></h4>
                    <p>
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusa ntium doloremque
                        laudantium totam rem aperiam
                    </p>
                    <a routerLink='/career' class="apply-btn">apply now <i class="far fa-long-arrow-right"></i></a>
                </div>
            </div>
            <div class="view-btn text-center mt-60">
                <a routerLink='/career' class="main-btn">View All Jobs</a>
            </div>
        </div>
    </section>
    <!--====== Job List End======-->

    <!--====== Client Area Start ======-->
    <section class="client-section">
        <div class="container">
            <div class="client-slider section-gap">
                <div class="row align-items-center justify-content-between" id="clientSlider">
                    <div class="col">
                        <a routerLink='/career' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/career' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/career' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/career' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/career' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/career' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/career' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/career' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/career' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/career' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>