<app-header1></app-header1>
<!--====== Breadcrumb part Start ======-->
    <section class="breadcrumb-section bg-img-c" style="background-image: url(assets/img/breadcrumb.jpg);">
        <div class="container">
            <div class="breadcrumb-text">
                <h1 class="page-title">Project Details</h1>
                <ul>
                    <li><a routerLink='/'>Home</a></li>
                    <li>Project Details</li>
                </ul>
            </div>
        </div>
        <div class="breadcrumb-shapes">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
        </div>
    </section>
    <!--====== Breadcrumb part End ======-->

    <!--====== Project Details Start ======-->
    <section class="project-details section-gap">
        <div class="container">
            <div class="project-thumb">
                <img src="assets/img/details/project-big-img.jpg" alt="Image">
            </div>

            <div class="project-content mt-60">
                <div class="row">
                    <div class="col-lg-8 order-2 order-lg-2">
                        <div class="content">
                            <h2>Business Strategy</h2>

                            <p class="mb-40">
                                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                                laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
                                architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas
                                sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
                                voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit
                                amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut
                                labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis
                                nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi
                                consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam
                                nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatu
                            </p>

                            <p>
                                Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                                velit, sed quia numquam eius modi tempora incidunt ut labore et dolore magnam aliquam
                                quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis
                                suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure
                                reprehenderit qui in ea voluptate velit esse quam nihil molestiae
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 order-1 order-lg-2">
                        <div class="details">
                            <ul>
                                <li>
                                    <h3>Project Name</h3>
                                    <p>Business Strategy</p>
                                </li>
                                <li>
                                    <h3>Clients Name</h3>
                                    <p>Logan Parkinson</p>
                                </li>
                                <li>
                                    <h3>Project Date</h3>
                                    <p>25 Aug 2020</p>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-12 order-3">
                        <div class="thumbs">
                            <div class="row justify-content-center">
                                <div class="col-md-4 col-sm-6 text-center">
                                    <img src="assets/img/details/project-01.jpg" alt="">
                                </div>
                                <div class="col-md-4 col-sm-6 text-center">
                                    <img src="assets/img/details/project-02.jpg" alt="">
                                </div>
                                <div class="col-md-4 col-sm-6 text-center">
                                    <img src="assets/img/details/project-03.jpg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 order-4">
                        <div class="content">
                            <p>
                                But I must explain to you how all this mistaken idea of denouncing pleasure and praising
                                pain was born and I will give you a complete account of the system, and expound the
                                actual teachings of the great explorer of the truth, the master-builder of human
                                happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure,
                                but because those who do not know how to pursue pleasure rationally encounter
                                consequences that are extremely painful. Nor again is there anyone who loves or pursues
                                or desires to obtain pain of itself, because it is pain, but because occasionally
                                circumstances occur in which toil and pain can procure him some great pleasure. To take
                                a trivial example, which of us ever undertakes laborious physical exercise, except to
                                obtain some advantage from it? But who has any right to find fault with a man who
                                chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain
                                that produces no resultant pleasure
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Project Details End ======-->

    <!--====== CTA Start ======-->
    <section class="cta-aection">
        <div class="container">
            <div class="cta-wrap bg-img-c" style="background-image: url(assets/img/lines/16.png);">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <div class="cta-content text-center">
                            <div class="section-title both-border mb-30">
                                <span class="title-tag">Get A Quote</span>
                                <h2 class="title">Feel Any Project For Business Consulting Get Started Us</h2>
                            </div>
                            <a routerLink='/contact-us' class="main-btn main-btn-3">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== CTA Start ======-->

    <!--====== Client Area Start ======-->
    <section class="client-section">
        <div class="container">
            <div class="client-slider section-gap line-top">
                <div class="row align-items-center justify-content-between" id="clientSlider">
                    <div class="col">
                        <a routerLink='/portfolio-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>