<app-header2></app-header2>
<!--====== Breadcrumb part Start ======-->
    <div class="breadcrumb-section bg-img-c" style="background-image: url(assets/img/breadcrumb.jpg);">
        <div class="container">
            <div class="breadcrumb-text">
                <h1 class="page-title">{{this.tituloImagen}}</h1>
               
            </div>
        </div>
      
    </div>
    <!--====== Breadcrumb part End ======-->


    <!-- Line -->
    <div class="container">
        <div class="line-bottom"></div>
    </div>

    <!--====== Project section Start ======-->
    <section class="project-section">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-8">
                    <!-- Section Title -->
                    <div class="section-title left-border">
                        <h2 style="color:white" class="title">{{this.tituloGeneralCards}}</h2>
                        <span style="margin-left: px;" class="title-tag">{{this.subtituloGeneralCards}}</span>

                    </div>
                </div>
         
            </div>

            <!-- Project Boxes -->
            <div class="row project-boxes mt-80 justify-content-center">

              
                    <div  *ngFor="let card of this.cards" class="col-lg-4 col-sm-6">
                        <app-card-component [titulo] = "splitString(card)[0]" [subtitulo] = "splitString(card)[1]" [imagen] = "splitString(card)[2]" [url] = "splitString(card)[3]"></app-card-component>
                    </div>
             
               
            </div>
        </div>
    </section>
    <!--====== Project section End ======-->

    <!--====== Client Area Start ======-->
    <section class="client-section">
        <div class="container">
            <div class="client-slider section-gap line-top">
                <div class="row align-items-center justify-content-between" id="clientSlider">
                    <div class="col">
                        <a routerLink='/team-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>