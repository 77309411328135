<app-header1></app-header1>
<!--====== Breadcrumb part Start ======-->
    <section class="breadcrumb-section bg-img-c" style="background-image: url(assets/img/breadcrumb.jpg);">
        <div class="container">
            <div class="breadcrumb-text">
                <h1 class="page-title">Our Services</h1>
                <ul>
                    <li><a routerLink='/'>Home</a></li>
                    <li>Services</li>
                </ul>
            </div>
        </div>
        <div class="breadcrumb-shapes">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
        </div>
    </section>
    <!--====== Breadcrumb part End ======-->

    <!--====== Service Section Start ======-->
    <section class="service-section section-gap">
        <div class="container">
            <!-- Section Title -->
            <div class="section-title text-center both-border mb-50">
                <span class="title-tag">Most Feature</span>
                <h2 class="title">We Provide Most Exclusive <br> Service For Business</h2>
            </div>
            <!-- Services Boxes -->
            <div class="row service-boxes justify-content-center">
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                    <div class="service-box-three">
                        <div class="icon">
                            <img src="assets/img/icons/01.png" alt="Icon">
                        </div>
                        <h3>Creative Idea</h3>
                        <p>Sed perspicia unde omnis</p>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="500ms">
                    <div class="service-box-three">
                        <div class="icon">
                            <img src="assets/img/icons/02.png" alt="Icon">
                        </div>
                        <h3>Business Strategy</h3>
                        <p>Quis autem velrepres hend</p>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                    <div class="service-box-three">
                        <div class="icon">
                            <img src="assets/img/icons/03.png" alt="Icon">
                        </div>
                        <h3>Relationship</h3>
                        <p>Sed perspicia unde omnis</p>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="700ms">
                    <div class="service-box-three">
                        <div class="icon">
                            <img src="assets/img/icons/04.png" alt="Icon">
                        </div>
                        <h3>Productivity</h3>
                        <p>Quis autem velrepres hend</p>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Service Section End ======-->

    <!--====== Feature Part start ======-->
    <section class="feature-section section-gap grey-bg">
        <div class="container">
            <div class="section-title text-center both-border mb-50">
                <span class="title-tag"> Popular Service </span>
                <h2 class="title">Our Awesome Services To <br> Grow Your Business</h2>
            </div>
            <!-- Feature boxes -->
            <div class="feature-boxes row justify-content-center">
                <div class="col-lg-4 col-md-6 col-10 col-tiny-12">
                    <div class="feature-box">
                        <div class="feature-bg bg-img-c" style="background-image: url(assets/img/feature/01.jpg);">
                        </div>
                        <div class="feature-desc">
                            <a routerLink='/service-detail' class="feature-link"><i class="fal fa-long-arrow-right"></i></a>
                            <h4>Free Consulting</h4>
                            <p>Join us for consultatoins</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-10 col-tiny-12">
                    <div class="feature-box">
                        <div class="feature-bg bg-img-c" style="background-image: url(assets/img/feature/02.jpg);">
                        </div>
                        <div class="feature-desc">
                            <a routerLink='/service-detail' class="feature-link"><i class="fal fa-long-arrow-right"></i></a>
                            <h4>Business Growth</h4>
                            <p>Join us for consultatoins</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-10 col-tiny-12">
                    <div class="feature-box">
                        <div class="feature-bg bg-img-c" style="background-image: url(assets/img/feature/03.jpg);">
                        </div>
                        <div class="feature-desc">
                            <a routerLink='/service-detail' class="feature-link"><i class="fal fa-long-arrow-right"></i></a>
                            <h4>IT Consulting</h4>
                            <p>Join us for consultatoins</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-10 col-tiny-12">
                    <div class="feature-box">
                        <div class="feature-bg bg-img-c" style="background-image: url(assets/img/feature/04.jpg);">
                        </div>
                        <div class="feature-desc">
                            <a routerLink='/service-detail' class="feature-link"><i class="fal fa-long-arrow-right"></i></a>
                            <h4>Investment Plan</h4>
                            <p>Join us for consultatoins</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-10 col-tiny-12">
                    <div class="feature-box">
                        <div class="feature-bg bg-img-c" style="background-image: url(assets/img/feature/05.jpg);">
                        </div>
                        <div class="feature-desc">
                            <a routerLink='/service-detail' class="feature-link"><i class="fal fa-long-arrow-right"></i></a>
                            <h4>Financial Planning</h4>
                            <p>Join us for consultatoins</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-10 col-tiny-12">
                    <div class="feature-box">
                        <div class="feature-bg bg-img-c" style="background-image: url(assets/img/feature/06.jpg);">
                        </div>
                        <div class="feature-desc">
                            <a routerLink='/service-detail' class="feature-link"><i class="fal fa-long-arrow-right"></i></a>
                            <h4>Relationship</h4>
                            <p>Join us for consultatoins</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Feature Part end ======-->

    <!--====== Why Choose Us Section Start ======-->
    <section class="wcu-section section-gap">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-6 col-md-10">
                    <div class="wcu-video wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                        <div class="video-poster-one bg-img-c"
                            style="background-image: url(assets/img/video-bg/poster-4.jpg);">
                        </div>
                        <div class="video-poster-two bg-img-c"
                            style="background-image: url(assets/img/video-bg/poster-5.jpg);">
                            <a href="https://www.youtube.com/watch?v=fEErySYqItI" class="popup-video">
                                <i class="fas fa-play"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-10">
                    <div class="wcu-text-two">
                        <div class="section-title left-border mb-40">
                            <span class="title-tag">Why We Are Best ?</span>
                            <h2 class="title">We Have Many Reasons Why Choose Us</h2>
                        </div>
                        <p>
                            Sedut perspiciatis unde omnis iste natus error sit voluptat em accusantium doloremque
                            laudantium, totam raperiaeaque ipsa quae ab illo inventore veritatis et quasi
                        </p>
                        <ul class="wcu-list clearfix">
                            <li>
                                <i class="far fa-check-circle"></i> Best Services
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Best Services
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Professional Advisor
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Professional Advisor
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Responsive Design
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Responsive Design
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Awesome Pricing
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Awesome Pricing
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Online Support
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Online Support
                            </li>
                        </ul>
                        <a routerLink='/contact-us' class="main-btn">Join With Us</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Why Choose Us Section End ======-->

    <!--====== CTA Start ======-->
    <section class="cta-aection">
        <div class="container">
            <div class="cta-wrap bg-img-c" style="background-image: url(assets/img/lines/16.png);">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <div class="cta-content text-center">
                            <div class="section-title both-border mb-30">
                                <span class="title-tag">Get A Quote</span>
                                <h2 class="title">Feel Any Project For Business Consulting Get Started Us</h2>
                            </div>
                            <a routerLink='/contact-us' class="main-btn main-btn-3">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== CTA Start ======-->

    <!--====== Client Area Start ======-->
    <section class="client-section">
        <div class="container">
            <div class="client-slider section-gap line-top">
                <div class="row align-items-center justify-content-between" id="clientSlider">
                    <div class="col">
                        <a routerLink='/service-two' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-two' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-two' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-two' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-two' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-two' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-two' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-two' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-two' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-two' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>