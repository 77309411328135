import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card-component',
  templateUrl: './card-component.component.html',
  styleUrls: ['./card-component.component.css']
})
export class CardComponentComponent implements OnInit {
  @Input() titulo: string;
  @Input() subtitulo: string;
  @Input() imagen: string;
  @Input() url: string;


  imagenOriginal: String;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  cambiarImagen(): void {
    this.imagen = this.imagen.split('.')[0]
    this.imagen += '.gif';
  }
  restaurarImagen(): void {
    this.imagen = this.imagen.split('.')[0]
    this.imagen += '.png';
  }
  navegarTo(){
    this.router.navigate([this.url]);

  }
}
