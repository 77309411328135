<app-header1></app-header1>
<!--====== Breadcrumb part Start ======-->
    <section class="breadcrumb-section bg-img-c" style="background-image: url(assets/img/breadcrumb.jpg);">
        <div class="container">
            <div class="breadcrumb-text">
                <h1 class="page-title">Shop Details</h1>
                <ul>
                    <li><a routerLink='/'>Home</a></li>
                    <li>Shop Details</li>
                </ul>
            </div>
        </div>
        <div class="breadcrumb-shapes">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
        </div>
    </section>
    <!--====== Breadcrumb part End ======-->

    <!--====== Shop Section Start ======-->
    <section class="shop-details-wrap">
        <div class="container">
            <div class="product-details section-gap">
                <div class="row align-items-center">
                    <div class="col-lg-8">
                        <div class="product-gallery clearfix">
                            <div class="product-gallery-arrow"></div>
                            <div class="gallery-slider-warp">
                                <div class="product-gallery-slider">
                                    <div class="single-gallery-itam" data-thumb="assets/img/shop/details-thumb-01.jpg">
                                        <img src="assets/img/shop/product-details-01.jpg" alt="Image">
                                    </div>
                                    <div class="single-gallery-itam" data-thumb="assets/img/shop/details-thumb-02.jpg">
                                        <img src="assets/img/shop/product-details-02.jpg" alt="Image">
                                    </div>
                                    <div class="single-gallery-itam" data-thumb="assets/img/shop/details-thumb-03.jpg">
                                        <img src="assets/img/shop/product-details-03.jpg" alt="Image">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="product-summary">
                            <h3 class="product-name">University Bags</h3>
                            <ul class="rating">
                                <li> <i class="fas fa-star"></i> </li>
                                <li> <i class="fas fa-star"></i> </li>
                                <li> <i class="fas fa-star"></i> </li>
                                <li> <i class="fas fa-star"></i> </li>
                                <li> <i class="fas fa-star-half"></i> </li>
                            </ul>
                            <span class="price">$89.00</span>
                            <div class="short-description">
                                <p>
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium dolorem
                                    que laudantium, totam rem aperiaaquipsa quae ab illo inventore veritatis
                                </p>
                                <p>
                                    Perspiciatis unde omnis iste sit voluptatem accusantium dolorem que laudantium,
                                    totam rem aperiaaquipsa quae ab illo
                                </p>
                            </div>
                            <div class="add-to-cart-form">
                                <form action="#">
                                    <div class="quantity-input">
                                        <div class="quantity-down" id="quantityDown">
                                            <i class="fal fa-minus"></i>
                                        </div>
                                        <input id="quantity" disabled type="text" value="1" name="quantity">
                                        <div class="quantity-up" id="quantityUP">
                                            <i class="fal fa-plus"></i>
                                        </div>
                                    </div>
                                    <div class="add-to-cart-btn">
                                        <button type="button">Add To Cart</button>
                                    </div>
                                </form>
                            </div>
                            <ul class="product-share">
                                <li class="title">Share Now:</li>
                                <li><a routerLink='/shop-detail'><i class="fab fa-facebook-f"></i></a></li>
                                <li><a routerLink='/shop-detail'><i class="fab fa-twitter"></i></a></li>
                                <li><a routerLink='/shop-detail'><i class="fab fa-google-plus-g"></i></a></li>
                                <li><a routerLink='/shop-detail'><i class="fab fa-youtube"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="product-details-tab">
                            <div class="tab-filter-nav">
                                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                    <a class="active" id="nav-desc" data-toggle="tab" href="#tab-desc" role="tab">
                                        Description
                                    </a>
                                    <a id="nav-info" data-toggle="tab" href="#tab-info" role="tab">
                                        Additional information
                                    </a>
                                    <a id="nav-review" data-toggle="tab" href="#tab-review" role="tab">
                                        Reviews (3)
                                    </a>
                                </div>
                            </div>
                            <div class="tab-content" id="nav-tabContent">
                                <div class="tab-pane fade show active" id="tab-desc" role="tabpanel">
                                    <div class="product-description">
                                        <p>
                                            Sorem ipsum dolor sit amet consectetur adipisicing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                            cillum dolore eu fugiat nulla pariatur xcepteur sint occaecat cupidatat non
                                            proident, sunt.
                                        </p>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                            cdolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                            Curabitur pretium tincidunt lacus. Nulla gravida orci a odio. Nullam varius,
                                            turpis et commodo pharetra, est eros bibendum elit, nec luctus magna felis
                                            sollicitudinInteger in mauris eu nibh euismod gravida. Duis ac tellus et
                                            risus vulputate vehicula. Donec lobortis risus a elit. Etiam tempor. Ut
                                            ullamcorper,
                                        </p>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="tab-info" role="tabpanel">
                                    <div class="additional-info">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>Weight</th>
                                                    <td>6.59 kg</td>
                                                </tr>
                                                <tr>
                                                    <th>Dimensions</th>
                                                    <td>43.31 x 26.70 x 64.50 cm</td>
                                                </tr>
                                                <tr>
                                                    <th>Color</th>
                                                    <td>Red, Black, Blue</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="tab-review" role="tabpanel">
                                    <div class="product-review">
                                        <ul class="review-list">
                                            <li class="single-review">
                                                <div class="review-thumb">
                                                    <img src="assets/img/testimonial/author.png" alt="Image">
                                                </div>
                                                <div class="review-content">
                                                    <h5 class="name">Jon Doe</h5>
                                                    <ul class="review-rating">
                                                        <li> <i class="fas fa-star"></i> </li>
                                                        <li> <i class="fas fa-star"></i> </li>
                                                        <li> <i class="fas fa-star"></i> </li>
                                                        <li> <i class="fas fa-star"></i> </li>
                                                        <li> <i class="fas fa-star-half"></i> </li>
                                                    </ul>
                                                    <p>
                                                        Harum quidem rerum facilis est et expedita distinctio. Nam
                                                        libero tempore, cum soluta nobis est eligendi optio cumque nihil
                                                        impedit quo minus id quod maxime
                                                        Harum quidem rerum facilis est et expedita distinctio. Nam
                                                        libero tempore, cum soluta nobis est eligendi optio cumque nihil
                                                        impedit quo minus id quod maxime
                                                    </p>
                                                </div>
                                                <ul class="children">
                                                    <li>
                                                        <div class="review-thumb">
                                                            <img src="assets/img/testimonial/author-4.png" alt="Image">
                                                        </div>
                                                        <div class="review-content">
                                                            <h5 class="name">Jon Doe</h5>
                                                            <ul class="review-rating">
                                                                <li> <i class="fas fa-star"></i> </li>
                                                                <li> <i class="fas fa-star"></i> </li>
                                                                <li> <i class="fas fa-star"></i> </li>
                                                                <li> <i class="fas fa-star"></i> </li>
                                                                <li> <i class="fas fa-star-half"></i> </li>
                                                            </ul>
                                                            <p>
                                                                Harum quidem rerum facilis est et expedita distinctio.
                                                                Nam libero tempore, cum soluta nobis est eligendi optio
                                                                cumque nihil impedit quo minus id quod maxime
                                                                Harum quidem rerum facilis est et expedita distinctio.
                                                                Nam libero tempore, cum soluta nobis est eligendi optio
                                                                cumque nihil impedit quo minus id quod maxime
                                                            </p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li class="single-review">
                                                <div class="review-thumb">
                                                    <img src="assets/img/testimonial/author-3.png" alt="Image">
                                                </div>
                                                <div class="review-content">
                                                    <h5 class="name">Jon Doe</h5>
                                                    <ul class="review-rating">
                                                        <li> <i class="fas fa-star"></i> </li>
                                                        <li> <i class="fas fa-star"></i> </li>
                                                        <li> <i class="fas fa-star"></i> </li>
                                                        <li> <i class="fas fa-star-half"></i> </li>
                                                    </ul>
                                                    <p>
                                                        Harum quidem rerum facilis est et expedita distinctio. Nam
                                                        libero tempore, cum soluta nobis est eligendi optio cumque nihil
                                                        impedit quo minus id quod maxime Harum quidem rerum facilis est
                                                        et expedita distinctio. Nam libero tempore, cum soluta nobis est
                                                        eligendi optio cumque nihil impedit quo minus id quod maxime
                                                    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="related-product">
                <h2 class="related-title">Related Products</h2>
                <div class="product-loop related-product-slider row">
                    <div class="col-lg-3 col-sm-6">
                        <div class="single-product">
                            <div class="product-img">
                                <img src="assets/img/shop/01.png" alt="image">
                                <div class="product-action">
                                    <a routerLink='/shop-detail'><i class="far fa-shopping-cart"></i></a>
                                    <a routerLink='/shop-detail'><i class="far fa-heart"></i></a>
                                    <a routerLink='/shop-detail'><i class="far fa-eye"></i></a>
                                </div>
                            </div>
                            <div class="product-desc">
                                <ul class="rating">
                                    <li> <i class="fas fa-star"></i> </li>
                                    <li> <i class="fas fa-star"></i> </li>
                                    <li> <i class="fas fa-star"></i> </li>
                                    <li> <i class="fas fa-star-half"></i> </li>
                                    <li> <i class="fas fa-star-half"></i> </li>
                                </ul>
                                <h5 class="title"><a routerLink='/shop-detail'>Smart Man Jacket</a></h5>
                                <span class="price">$25.59</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="single-product">
                            <div class="product-img">
                                <img src="assets/img/shop/02.png" alt="image">
                                <div class="product-action">
                                    <a routerLink='/shop-detail'><i class="far fa-shopping-cart"></i></a>
                                    <a routerLink='/shop-detail'><i class="far fa-heart"></i></a>
                                    <a routerLink='/shop-detail'><i class="far fa-eye"></i></a>
                                </div>
                            </div>
                            <div class="product-desc">
                                <ul class="rating">
                                    <li> <i class="fas fa-star"></i> </li>
                                    <li> <i class="fas fa-star"></i> </li>
                                    <li> <i class="fas fa-star"></i> </li>
                                    <li> <i class="fas fa-star-half"></i> </li>
                                    <li> <i class="fas fa-star-half"></i> </li>
                                </ul>
                                <h5 class="title"><a routerLink='/shop-detail'>Smart Man Jacket</a></h5>
                                <span class="price">$25.59</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="single-product">
                            <div class="product-img">
                                <img src="assets/img/shop/03.png" alt="image">
                                <div class="product-action">
                                    <a routerLink='/shop-detail'><i class="far fa-shopping-cart"></i></a>
                                    <a routerLink='/shop-detail'><i class="far fa-heart"></i></a>
                                    <a routerLink='/shop-detail'><i class="far fa-eye"></i></a>
                                </div>
                            </div>
                            <div class="product-desc">
                                <ul class="rating">
                                    <li> <i class="fas fa-star"></i> </li>
                                    <li> <i class="fas fa-star"></i> </li>
                                    <li> <i class="fas fa-star"></i> </li>
                                    <li> <i class="fas fa-star-half"></i> </li>
                                    <li> <i class="fas fa-star-half"></i> </li>
                                </ul>
                                <h5 class="title"><a routerLink='/shop-detail'>Smart Man Jacket</a></h5>
                                <span class="price">$25.59</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="single-product">
                            <div class="product-img">
                                <img src="assets/img/shop/04.png" alt="image">
                                <div class="product-action">
                                    <a routerLink='/shop-detail'><i class="far fa-shopping-cart"></i></a>
                                    <a routerLink='/shop-detail'><i class="far fa-heart"></i></a>
                                    <a routerLink='/shop-detail'><i class="far fa-eye"></i></a>
                                </div>
                            </div>
                            <div class="product-desc">
                                <ul class="rating">
                                    <li> <i class="fas fa-star"></i> </li>
                                    <li> <i class="fas fa-star"></i> </li>
                                    <li> <i class="fas fa-star"></i> </li>
                                    <li> <i class="fas fa-star-half"></i> </li>
                                    <li> <i class="fas fa-star-half"></i> </li>
                                </ul>
                                <h5 class="title"><a routerLink='/shop-detail'>Smart Man Jacket</a></h5>
                                <span class="price">$25.59</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="single-product">
                            <div class="product-img">
                                <img src="assets/img/shop/05.png" alt="image">
                                <div class="product-action">
                                    <a routerLink='/shop-detail'><i class="far fa-shopping-cart"></i></a>
                                    <a routerLink='/shop-detail'><i class="far fa-heart"></i></a>
                                    <a routerLink='/shop-detail'><i class="far fa-eye"></i></a>
                                </div>
                            </div>
                            <div class="product-desc">
                                <ul class="rating">
                                    <li> <i class="fas fa-star"></i> </li>
                                    <li> <i class="fas fa-star"></i> </li>
                                    <li> <i class="fas fa-star"></i> </li>
                                    <li> <i class="fas fa-star-half"></i> </li>
                                    <li> <i class="fas fa-star-half"></i> </li>
                                </ul>
                                <h5 class="title"><a routerLink='/shop-detail'>Smart Man Jacket</a></h5>
                                <span class="price">$25.59</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="single-product">
                            <div class="product-img">
                                <img src="assets/img/shop/06.png" alt="image">
                                <div class="product-action">
                                    <a routerLink='/shop-detail'><i class="far fa-shopping-cart"></i></a>
                                    <a routerLink='/shop-detail'><i class="far fa-heart"></i></a>
                                    <a routerLink='/shop-detail'><i class="far fa-eye"></i></a>
                                </div>
                            </div>
                            <div class="product-desc">
                                <ul class="rating">
                                    <li> <i class="fas fa-star"></i> </li>
                                    <li> <i class="fas fa-star"></i> </li>
                                    <li> <i class="fas fa-star"></i> </li>
                                    <li> <i class="fas fa-star-half"></i> </li>
                                    <li> <i class="fas fa-star-half"></i> </li>
                                </ul>
                                <h5 class="title"><a routerLink='/shop-detail'>Smart Man Jacket</a></h5>
                                <span class="price">$25.59</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="single-product">
                            <div class="product-img">
                                <img src="assets/img/shop/07.png" alt="image">
                                <div class="product-action">
                                    <a routerLink='/shop-detail'><i class="far fa-shopping-cart"></i></a>
                                    <a routerLink='/shop-detail'><i class="far fa-heart"></i></a>
                                    <a routerLink='/shop-detail'><i class="far fa-eye"></i></a>
                                </div>
                            </div>
                            <div class="product-desc">
                                <ul class="rating">
                                    <li> <i class="fas fa-star"></i> </li>
                                    <li> <i class="fas fa-star"></i> </li>
                                    <li> <i class="fas fa-star"></i> </li>
                                    <li> <i class="fas fa-star-half"></i> </li>
                                    <li> <i class="fas fa-star-half"></i> </li>
                                </ul>
                                <h5 class="title"><a routerLink='/shop-detail'>Smart Man Jacket</a></h5>
                                <span class="price">$25.59</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="single-product">
                            <div class="product-img">
                                <img src="assets/img/shop/08.png" alt="image">
                                <div class="product-action">
                                    <a routerLink='/shop-detail'><i class="far fa-shopping-cart"></i></a>
                                    <a routerLink='/shop-detail'><i class="far fa-heart"></i></a>
                                    <a routerLink='/shop-detail'><i class="far fa-eye"></i></a>
                                </div>
                            </div>
                            <div class="product-desc">
                                <ul class="rating">
                                    <li> <i class="fas fa-star"></i> </li>
                                    <li> <i class="fas fa-star"></i> </li>
                                    <li> <i class="fas fa-star"></i> </li>
                                    <li> <i class="fas fa-star-half"></i> </li>
                                    <li> <i class="fas fa-star-half"></i> </li>
                                </ul>
                                <h5 class="title"><a routerLink='/shop-detail'>Smart Man Jacket</a></h5>
                                <span class="price">$25.59</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Shop Section End ======-->
    <app-footer1></app-footer1>