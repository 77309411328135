import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-what-we-do',
  templateUrl: './what-we-do.component.html',
  styleUrls: ['./what-we-do.component.css']
})
export class WhatWeDoComponent implements OnInit {

  tituloImagen: String;
  tituloGeneralCards: String;
  subtituloGeneralCards: String;
  //formato del array -> '["titulo1:subtitulo1:imagen1","titulo2:subtitulo2:imagen2"...]'
  cards: String[];

  constructor() {
    this.tituloImagen = '¿Qué hacemos?';
    this.tituloGeneralCards = 'Que servicios ofrecemos';
    this.subtituloGeneralCards = 'en que podemos ayudarte';
    this.cards = ["VIDEO MARKETING:NUESTRO PUNTO FUERTE:gifHabanero.png:video-marketing",
                "REDES SOCIALES:Nos encargamos de gestionar todas tus plataformas de redes sociales:gifInsta.png:video-marketing",
                "BRANDING E IDENTIDAD CORPORATIVA:Potenciamos tu marca con un diseño actualizado:gifBartoll.png:video-marketing",
                "ACCIONES IMPACTO: :gifImpacto.png:video-marketing",
                "FOTOGRAFÍA CORPORATIVA:Capturamos la esencia de tu negocio en cada imagen:gifFotografia.png:video-marketing",
                "MARKETING DE INFLUENCIA:Ampliamos tu audiencia con colaboraciones de calidad:gifMarketingInfluencia.png:video-marketing",
                "EVENTOS:Organizamos, gestionamos y comunicamos experiencias que dejan huella:gifEventos.png:video-marketing"];

   }

   splitString(inputString: string): string[] {
    return inputString.split(':');
  }

  ngOnInit(): void {
  }

  
}
