<app-header1></app-header1>
<!--====== Breadcrumb part Start ======-->
    <section class="breadcrumb-section bg-img-c" style="background-image: url(assets/img/breadcrumb.jpg);">
        <div class="container">
            <div class="breadcrumb-text">
                <h1 class="page-title">Services Details</h1>
                <ul>
                    <li><a routerLink='/'>Home</a></li>
                    <li>Services Details</li>
                </ul>
            </div>
        </div>
        <div class="breadcrumb-shapes">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
        </div>
    </section>
    <!--====== Breadcrumb part End ======-->

    <!--====== Service Details Start ======-->
    <section class="service-details section-gap">
        <div class="container">
            <div class="row">
                <!-- Dettails Content -->
                <div class="col-lg-8">
                    <div class="service-details-content">
                        <div class="main-thumb mb-40">
                            <img src="assets/img/details/service-01.jpg" alt="image">
                        </div>
                        <h2 class="title">Financial Planning</h2>
                        <p>
                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                            laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
                            architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
                            aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
                            voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
                            consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et
                            dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum
                            exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi
                            consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil
                            molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatu
                        </p>
                        <ul class="circle-check-list mt-50 mb-50">
                            <li>
                                <i class="fas fa-check"></i>
                                Adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore
                                magnam aliquam quaerat voluptatem enim ad minima veniam quis nostrum
                            </li>
                            <li>
                                <i class="fas fa-check"></i>
                                Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nmolestiae
                                consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatu
                            </li>
                            <li>
                                <i class="fas fa-check"></i>
                                But I must explain to you how all this mistaken idea of denouncing pleasure praising
                                pain was born and I will give you complete account of the system expound
                            </li>
                        </ul>
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-6">
                                <div class="image text-center mb-40">
                                    <img src="assets/img/details/service-02.jpg" alt="Image">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <h3 class="sub-title">How To Buildup Buisnes</h3>
                                <p>
                                    Nor again is there anyone who loves or pursues or desires to obtain pain of itself,
                                    because it is pain, but because occasionally circumstances occur in which toil and
                                    pain can procure him some great pleasure.
                                </p>
                                <ul class="check-list mt-30 mb-40">
                                    <li><i class="fas fa-check"></i>Business & Consulting Agency</li>
                                    <li><i class="fas fa-check"></i>Awards Winning Business Comapny</li>
                                    <li><i class="fas fa-check"></i>Complete Guide To Mechanical</li>
                                    <li><i class="fas fa-check"></i>Firebase Authentication & Database</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="faq-section mt-40">
                        <!-- FAQ LOOP -->
                        <div class="accordion faq-loop border-style" id="faqAccordion">
                            <div class="card">
                                <div class="card-header">
                                    <h6 class="collapsed" data-toggle="collapse" data-target="#collapseOne">
                                        How To Create A Mobile App In Expo And Firebase

                                        <span class="icons">
                                            <i class="far fa-plus"></i>
                                        </span>
                                    </h6>
                                </div>

                                <div id="collapseOne" class="collapse" data-parent="#faqAccordion">
                                    <div class="card-body">
                                        Nor again is there anyone who loves or pursues or desires to obtain pain of
                                        itself, because it is pain, but because occasionally circumstances occur in
                                        which toil and pain can procure him some
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header active-header">
                                    <h6 class="collapsed" data-toggle="collapse" data-target="#collapseTwo">
                                        Smashing Podcast Episode With Ben How ?

                                        <span class="icons">
                                            <i class="far fa-minus"></i>
                                        </span>
                                    </h6>
                                </div>

                                <div id="collapseTwo" class="collapse show" data-parent="#faqAccordion">
                                    <div class="card-body">
                                        Nor again is there anyone who loves or pursues or desires to obtain pain of
                                        itself, because it is pain, but because occasionally circumstances occur in
                                        which toil and pain can procure him some
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <h6 class="collapsed" data-toggle="collapse" data-target="#collapseThree">
                                        Learning Resources Challenging Workshops ?

                                        <span class="icons">
                                            <i class="far fa-plus"></i>
                                        </span>
                                    </h6>
                                </div>

                                <div id="collapseThree" class="collapse" data-parent="#faqAccordion">
                                    <div class="card-body">
                                        Nor again is there anyone who loves or pursues or desires to obtain pain of
                                        itself, because it is pain, but because occasionally circumstances occur in
                                        which toil and pain can procure him some
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <h6 class="collapsed" data-toggle="collapse" data-target="#collapseFour">
                                        Micro-Typography: How To Space Kern ?

                                        <span class="icons">
                                            <i class="far fa-plus"></i>
                                        </span>
                                    </h6>
                                </div>
                                <div id="collapseFour" class="collapse" data-parent="#faqAccordion">
                                    <div class="card-body">
                                        Nor again is there anyone who loves or pursues or desires to obtain pain of
                                        itself, because it is pain, but because occasionally circumstances occur in
                                        which toil and pain can procure him some
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Faq LOOP -->
                    </div>
                </div>
                <!-- Sidebar -->
                <div class="col-lg-4 col-md-8">
                    <app-service-sidebar></app-service-sidebar>
                </div>
            </div>
        </div>
    </section>
    <!--====== Service Details End ======-->

    <!--====== CTA Start ======-->
    <section class="cta-aection">
        <div class="container">
            <div class="cta-wrap bg-img-c" style="background-image: url(assets/img/lines/16.png);">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <div class="cta-content text-center">
                            <div class="section-title both-border mb-30">
                                <span class="title-tag">Get A Quote</span>
                                <h2 class="title">Feel Any Project For Business Consulting Get Started Us</h2>
                            </div>
                            <a routerLink='/contact-us' class="main-btn main-btn-3">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== CTA Start ======-->

    <!--====== Client Area Start ======-->
    <section class="client-section">
        <div class="container">
            <div class="client-slider section-gap line-top">
                <div class="row align-items-center justify-content-between" id="clientSlider">
                    <div class="col">
                        <a routerLink='/service-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-detail' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>