<header  class="sticky-header">
		<!-- Header Menu  -->
		<div  class="header-nav" style=" background-color: #1b1c1d;">
			<div class="container-fluid container-1600">
				<div class="nav-container">
					<!-- Site Logo -->
					<div class="site-logo">
						<a routerLink='/'><img  width="300" height="35" src="assets/img/conAcentoBlackLogoGrande.png" alt="Logo"></a>
					</div>

					<!-- Main Menu -->
					<div class="nav-menu d-lg-flex align-items-center">

						<!-- Navbar Close Icon -->
						<div class="navbar-close">
							<div class="cross-wrap"><span></span><span></span></div>
						</div>

						<!-- Mneu Items -->
						<div class="menu-items">
							<ul>
									<li><a routerLink='/contact-us' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Demostraciones</a></li>
									<li><a routerLink='/team' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Nosotros</a></li>
									<li><a routerLink='/team-detail' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Nuestros clientes</a></li>
									<li><a routerLink='/contact-us' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contáctanos</a></li>
								</ul>
						</div>
						<!-- Pushed Item -->
						<div class="nav-pushed-item"></div>
					</div>
                    <!-- Navbar Extra  -->
                    <div (click)="toggleDiv()" class="navbar-extra d-flex align-items-center">
                        <div class="offcanvas-toggler">
                            <span><span></span><span></span><span></span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>


		
               
         
    </header>

