
<!--====== Banner part start ======-->
<section class="banner-section">
    
    <div class="banner-slider" id="bannerSlider">
        <div class="single-banner">
            <div class="container">
                <!--
                <video  [muted]="'muted'" autoplay id="myVideo" >
                    <source src="assets/img/LGvideoDemo.mp4" type="video/mp4">
                </video>
                -->
                <div class="row">
                    <div class="col-lg-10">
                        
                        <div class="banner-content">
                            
                                <video  [muted]="'muted'" autoplay id="myVideo" >
                                    <source src="assets/img/LGvideoDemo.mp4" type="video/mp4">
                                </video>
                              
                           
                            <span class="promo-text" data-animation="fadeInDown" data-delay="0.8s">
                                business & consulting
                            </span>
                            <h1 data-animation="fadeInUp" data-delay="1s">
                                Making Difference <br> Growth Your Business <br> With Modern Ideas
                            </h1>
                            <ul class="btn-wrap">
                                <li data-animation="fadeInLeft" data-delay="1.2s">
                                    <a routerLink='/about-us' class="main-btn main-btn-4">Get Started Now</a>
                                </li>
                                <li data-animation="fadeInRight" data-delay="1.4s">
                                    <a routerLink='/service-one' class="main-btn main-btn-2">Our Services</a>
                                </li>
                            </ul>
                            
                        </div>
                    </div>
                </div>
                
            </div>
        
        </div>
        <!--====== 
                <div class="single-banner" style="background-image: url(https://usercontent.one/wp/www.researchgermany.com/wp-content/uploads/2022/07/UK.jpg);">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10">
                        <div class="banner-content">
                            <span class="promo-text" data-animation="fadeInDown" data-delay="0.8s">
                                business & consulting
                            </span>
                            <h1 data-animation="fadeInUp" data-delay="1s">
                                Making Difference <br> Growth Your Business <br> With Modern Ideas
                            </h1>
                            <ul class="btn-wrap">
                                <li data-animation="fadeInLeft" data-delay="1.2s">
                                    <a routerLink='/about-us' class="main-btn main-btn-4">Get Started Now</a>
                                </li>
                                <li data-animation="fadeInRight" data-delay="1.4s">
                                    <a routerLink='/service-one' class="main-btn main-btn-2">Our Services</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="banner-shapes">
                <div class="one"></div>
                <div class="two"></div>
                <div class="three"></div>
                <div class="four"></div>
            </div>
        </div>
            
            ======-->
    </div>

    
</section>
<!--====== Banner part end ======-->