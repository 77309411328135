<header class="header-two sticky-header">
        <!-- Header Menu  -->
        <div class="header-nav sticky-nav">
            <div class="container-fluid container-1600">
                <div class="nav-container">
                    <!-- Site Logo -->
                    <div class="site-logo">
						<a class="bigIcon" routerLink='/'><img  width="300" height="35" src="assets/img/conAcentoLogoGrande.png" alt="Logo"></a>
                        <a class="littleIcon" routerLink='/'><img  width="60" height="60" src="assets/img/conAcentoLogoPeque.png" alt="Logo"></a>

                    </div>

                     <!-- Main Menu -->
					<div class="nav-menu d-lg-flex align-items-center">

						<!-- Navbar Close Icon -->
						<div class="navbar-close">
							<div class="cross-wrap"><span></span><span></span></div>
						</div>

						<!-- Mneu Items -->
						<div class="menu-items">
							<ul>
									<li><a routerLink='/what-we-do' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{this.whatWeDo}}</a></li>
									<li><a routerLink='/team' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Nosotros</a></li>
									<li><a routerLink='/team-detail' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Nuestros clientes</a></li>
									<li><a routerLink='/contact-us' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contáctanos</a></li>
								</ul>
						</div>
						<!-- Pushed Item -->
						<div class="nav-pushed-item"></div>
					</div>
                    <!-- Navbar Extra  -->
                    <div class="navbar-extra d-flex align-items-center">
                       
                        <!-- off canvas menu toggler-->
                        <div class="offcanvas-toggler">
                            <span><span></span><span></span><span></span></span>
                        </div>
                        <!-- Navbar Toggler -->
                        <div class="navbar-toggler">
                            <span></span><span></span><span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <!--====== OFF CANVAS START ======-->
    <div class="offcanvas-wrapper">
        <div class="offcanvas-overly"></div>
        <div class="offcanvas-widget">
            <a href="javascript:void(0)" class="offcanvas-close"><i class="fal fa-times"></i></a>
            <!-- About Widget -->
            <div class="widget about-widget">
                <div class="logo mb-30">
                    <img src="assets/img/logo-3.png" alt="Image">
                </div>
                <p>
                    Sedut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                    totam raperiaeaque ipsa quae ab illo inventore veritatis et quasi.
                </p>
            </div>
            <!-- Contact information Widget -->
            <div class="widget contact-info-widget">
                <h5 class="widget-title">Contact Us</h5>
                <ul>
                    <li>
                        <i class="far fa-map-marked-alt"></i>
                        6701 Democracy Blvd, Suite 300, USA
                    </li>
                    <li>
                        <i class="far fa-phone"></i>
                        0800-123456 (24/7 Support Line)
                    </li>
                    <li>
                        <i class="far fa-envelope-open"></i>
                        info@example.com
                    </li>
                </ul>
            </div>
            <!-- tweets Widget -->
            <div class="widget tweets-widget">
                <h5 class="widget-title">Recent tweets</h5>
                <ul>
                    <li>
                        <a href="javascript:void(0)">
                            <i class="fab fa-twitter"></i>
                            <span>@username:</span> Sedut perspiciatis unde omnis iste natus error sit
                            voluptatem accusantium...
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)">
                            <i class="fab fa-twitter"></i>
                            <span>@username:</span> Sedut perspiciatis unde omnis iste natus error sit
                            voluptatem accusantium...
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)">
                            <i class="fab fa-twitter"></i>
                            <span>@username:</span> Sedut perspiciatis unde omnis iste natus error sit
                            voluptatem accusantium...
                        </a>
                    </li>
                </ul>
            </div>
            <!-- Social Link -->
            <div class="widget social-link">
                <h5 class="widget-title">Follow us</h5>
                <ul>
                    <li><a href="javascript:void(0)"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="javascript:void(0)"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="javascript:void(0)"><i class="fab fa-behance"></i></a></li>
                    <li><a href="javascript:void(0)"><i class="fab fa-linkedin"></i></a></li>
                    <li><a href="javascript:void(0)"><i class="fab fa-google"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
    <!--====== OFF CANVAS END ======-->