<app-header1></app-header1>
<!--====== Breadcrumb part Start ======-->
    <div class="breadcrumb-section bg-img-c" style="background-image: url(assets/img/breadcrumb.jpg);">
        <div class="container">
            <div class="breadcrumb-text">
                <h1 class="page-title">Blog Standard</h1>
                <ul>
                    <li><a routerLink='/'>Home</a></li>
                    <li>Blog</li>
                </ul>
            </div>
        </div>
        <div class="breadcrumb-shapes">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
        </div>
    </div>
    <!--====== Breadcrumb part End ======-->

    <!--====== Blog Section Start ======-->
    <section class="blog-section section-gap-top">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <!-- Blog loop(Standard) -->
                    <div class="blog-loop standard-blog row">
                        <!-- Single Post -->
                        <div class="col-12">
                            <div class="single-post-box">
                                <div class="post-thumb">
                                    <img src="assets/img/blog/01.jpg" alt="Image">
                                </div>
                                <div class="post-meta">
                                    <ul>
                                        <li><i class="far fa-user"></i><a routerLink='/blog-standard'>Nichel Jhon</a></li>
                                        <li><i class="far fa-calendar-alt"></i><a routerLink='/blog-standard'>25 Aug 2020</a></li>
                                        <li><i class="far fa-comments"></i><a routerLink='/blog-standard'>Comments (05)</a></li>
                                    </ul>
                                </div>
                                <div class="post-content">
                                    <h3 class="title">
                                        <a routerLink='/blog-detail'>
                                            Inspired Design Decisions With Herb Typography Can Be As Exciting As
                                            Illustration & Photog
                                        </a>
                                    </h3>
                                    <p>
                                        But must explain to you how all this mistaken idea of denouncing pleasure and
                                        praising pain was born and will give you a complete account of the system, and
                                        expound the actual teachings of the great explorer of the truth, the
                                        master-builder of human happiness. No one rejects, dislikes, or avoids pleasure
                                        itse
                                    </p>
                                    <a routerLink='/blog-detail' class="main-btn">Learn More</a>
                                </div>
                            </div>
                        </div>
                        <!-- Single Post -->
                        <div class="col-12">
                            <div class="single-post-box">
                                <div class="post-thumb">
                                    <img src="assets/img/blog/02.jpg" alt="Image">
                                </div>
                                <div class="post-meta">
                                    <ul>
                                        <li><i class="far fa-user"></i><a routerLink='/blog-standard'>Nichel Jhon</a></li>
                                        <li><i class="far fa-calendar-alt"></i><a routerLink='/blog-standard'>25 Aug 2020</a></li>
                                        <li><i class="far fa-comments"></i><a routerLink='/blog-standard'>Comments (05)</a></li>
                                    </ul>
                                </div>
                                <div class="post-content">
                                    <h3 class="title">
                                        <a routerLink='/blog-detail'>
                                            What Should You Do When A Web Design Trend Becomes Too Popular? Typography
                                        </a>
                                    </h3>
                                    <p>
                                        But must explain to you how all this mistaken idea of denouncing pleasure and
                                        praising pain was born and will give you a complete account of the system, and
                                        expound the actual teachings of the great explorer of the truth, the
                                        master-builder of human happiness. No one rejects, dislikes, or avoids pleasure
                                        itse.
                                    </p>
                                    <a routerLink='/blog-detail' class="main-btn">Learn More</a>
                                </div>
                            </div>
                        </div>
                        <!-- Single Post -->
                        <div class="col-12">
                            <div class="single-post-box quote-post">
                                <div class="post-meta">
                                    <ul>
                                        <li><i class="far fa-user"></i><a routerLink='/blog-standard'>Nichel Jhon</a></li>
                                        <li><i class="far fa-calendar-alt"></i><a routerLink='/blog-standard'>25 Aug 2020</a></li>
                                        <li><i class="far fa-comments"></i><a routerLink='/blog-standard'>Comments (05)</a></li>
                                    </ul>
                                </div>
                                <div class="post-content">
                                    <h3 class="title">
                                        <a routerLink='/blog-detail'>
                                            Visual Design Language The Building Blocks Of Design Web Design Trend
                                            Becomes
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <!-- Single Post -->
                        <div class="col-12">
                            <div class="single-post-box video-post">
                                <div class="post-video">
                                    <img src="assets/img/blog/03.jpg" alt="Image">
                                    <a href="https://www.youtube.com/watch?v=fEErySYqItI" class="popup-video">
                                        <i class="fas fa-play"></i>
                                    </a>
                                </div>
                                <div class="post-meta">
                                    <ul>
                                        <li><i class="far fa-user"></i><a routerLink='/blog-standard'>Nichel Jhon</a></li>
                                        <li><i class="far fa-calendar-alt"></i><a routerLink='/blog-standard'>25 Aug 2020</a></li>
                                        <li><i class="far fa-comments"></i><a routerLink='/blog-standard'>Comments (05)</a></li>
                                    </ul>
                                </div>
                                <div class="post-content">
                                    <h3 class="title">
                                        <a routerLink='/blog-detail'>
                                            How Increase Mobile Conversions With Category Page DesignTrend Becomes
                                            Typography
                                        </a>
                                    </h3>
                                    <p>
                                        But must explain to you how all this mistaken idea of denouncing pleasure and
                                        praising pain was born and will give you a complete account of the system, and
                                        expound the actual teachings of the great explorer of the truth, the
                                        master-builder of human happiness. No one rejects, dislikes, or avoids pleasure
                                        itse.
                                    </p>
                                    <a routerLink='/blog-detail' class="main-btn">Learn More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="single-post-box no-thumb">
                                <div class="post-meta">
                                    <ul>
                                        <li><i class="far fa-user"></i><a routerLink='/blog-standard'>Nichel Jhon</a></li>
                                        <li><i class="far fa-calendar-alt"></i><a routerLink='/blog-standard'>25 Aug 2020</a></li>
                                        <li><i class="far fa-comments"></i><a routerLink='/blog-standard'>Comments (05)</a></li>
                                    </ul>
                                </div>
                                <div class="post-content">
                                    <h3 class="title">
                                        <a routerLink='/blog-detail'>
                                            Inspired Design Decisions With Otto Storch When Idea Copy Art And Typography
                                        </a>
                                    </h3>
                                    <p>
                                        But must explain to you how all this mistaken idea of denouncing pleasure and
                                        praising pain born and give you a complete account of the system, and expound
                                        the actual teachings of the great explorer of the the
                                        master-builder of human happiness. No one rejects,
                                    </p>
                                    <a routerLink='/blog-detail' class="main-btn">Learn More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Pagination -->
                    <div class="pagination-wrap">
                        <ul>
                            <li><a routerLink='/blog-standard'><i class="far fa-angle-left"></i></a></li>
                            <li class="active"><a routerLink='/blog-standard'>01</a></li>
                            <li><a routerLink='/blog-standard'>02</a></li>
                            <li><a routerLink='/blog-standard'>03</a></li>
                            <li><a routerLink='/blog-standard'><i class="far fa-angle-right"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-8">
                    <!-- sidebar -->
                    <app-blog-sidebar></app-blog-sidebar>
                </div>
            </div>
        </div>
    </section>
    <!--====== Blog Section End ======-->

    <!--====== Client Area Start ======-->
    <section class="client-section">
        <div class="container">
            <div class="client-slider section-gap">
                <div class="row align-items-center justify-content-between" id="clientSlider">
                    <div class="col">
                        <a routerLink='/blog-standard' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-standard' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-standard' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-standard' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-standard' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-standard' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-standard' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-standard' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-standard' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-standard' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>