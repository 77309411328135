<app-header1></app-header1>
<!--====== Breadcrumb part Start ======-->
    <section class="breadcrumb-section bg-img-c" style="background-image: url(assets/img/breadcrumb.jpg);">
        <div class="container">
            <div class="breadcrumb-text">
                <h1 class="page-title">Team Members</h1>
                <ul>
                    <li><a routerLink='/'>Home</a></li>
                    <li>Our Team</li>
                </ul>
            </div>
        </div>
        <div class="breadcrumb-shapes">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
        </div>
    </section>
    <!--====== Breadcrumb part End ======-->

    <!--====== Team Section Start ======-->
    <section class="team-section section-gap">
        <div class="container">
            <!-- Section Title -->
            <div class="section-title mb-40 both-border text-center">
                <span class="title-tag">Meet Our Advisors</span>
                <h2 class="title">We Have An Exclusive <br> Team Member</h2>
            </div>

            <!-- Team Boxes -->
            <div class="row team-members justify-content-center">
                <div class="col-lg-3 col-md-4 col-6 col-tiny-12">
                    <div class="team-member">
                        <div class="member-picture-wrap">
                            <div class="member-picture">
                                <img src="assets/img/team/01.jpg" alt="TeamMember">
                                <div class="social-icons">
                                    <a routerLink='/team'>
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                    <a routerLink='/team'>
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                    <a routerLink='/team'>
                                        <i class="fab fa-behance"></i>
                                    </a>
                                    <a routerLink='/team'>
                                        <i class="fab fa-instagram"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="member-desc">
                            <h3 class="name"><a routerLink='/team-detail'>John N. Holmes</a></h3>
                            <span class="pro">Business Developer</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6 col-tiny-12">
                    <div class="team-member">
                        <div class="member-picture-wrap">
                            <div class="member-picture">
                                <img src="assets/img/team/02.jpg" alt="TeamMember">
                                <div class="social-icons">
                                    <a routerLink='/team'>
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                    <a routerLink='/team'>
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                    <a routerLink='/team'>
                                        <i class="fab fa-behance"></i>
                                    </a>
                                    <a routerLink='/team'>
                                        <i class="fab fa-instagram"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="member-desc">
                            <h3 class="name"><a routerLink='/team-detail'>Kenneth A. Spencer</a></h3>
                            <span class="pro">Business Developer</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6 col-tiny-12">
                    <div class="team-member">
                        <div class="member-picture-wrap">
                            <div class="member-picture">
                                <img src="assets/img/team/03.jpg" alt="TeamMember">
                                <div class="social-icons">
                                    <a routerLink='/team'>
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                    <a routerLink='/team'>
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                    <a routerLink='/team'>
                                        <i class="fab fa-behance"></i>
                                    </a>
                                    <a routerLink='/team'>
                                        <i class="fab fa-instagram"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="member-desc">
                            <h3 class="name"><a routerLink='/team-detail'>David K. Marble</a></h3>
                            <span class="pro">Business Developer</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6 col-tiny-12">
                    <div class="team-member">
                        <div class="member-picture-wrap">
                            <div class="member-picture">
                                <img src="assets/img/team/04.jpg" alt="TeamMember">
                                <div class="social-icons">
                                    <a routerLink='/team'>
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                    <a routerLink='/team'>
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                    <a routerLink='/team'>
                                        <i class="fab fa-behance"></i>
                                    </a>
                                    <a routerLink='/team'>
                                        <i class="fab fa-instagram"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="member-desc">
                            <h3 class="name"><a routerLink='/team-detail'>Raymond Grimes</a></h3>
                            <span class="pro">Business Developer</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6 col-tiny-12">
                    <div class="team-member">
                        <div class="member-picture-wrap">
                            <div class="member-picture">
                                <img src="assets/img/team/10.jpg" alt="TeamMember">
                                <div class="social-icons">
                                    <a routerLink='/team'>
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                    <a routerLink='/team'>
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                    <a routerLink='/team'>
                                        <i class="fab fa-behance"></i>
                                    </a>
                                    <a routerLink='/team'>
                                        <i class="fab fa-instagram"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="member-desc">
                            <h3 class="name"><a routerLink='/team-detail'>Sebastian Cartwright</a></h3>
                            <span class="pro">Business Developer</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6 col-tiny-12">
                    <div class="team-member">
                        <div class="member-picture-wrap">
                            <div class="member-picture">
                                <img src="assets/img/team/11.jpg" alt="TeamMember">
                                <div class="social-icons">
                                    <a routerLink='/team'>
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                    <a routerLink='/team'>
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                    <a routerLink='/team'>
                                        <i class="fab fa-behance"></i>
                                    </a>
                                    <a routerLink='/team'>
                                        <i class="fab fa-instagram"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="member-desc">
                            <h3 class="name"><a routerLink='/team-detail'>Joseph Browne</a></h3>
                            <span class="pro">Business Developer</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6 col-tiny-12">
                    <div class="team-member">
                        <div class="member-picture-wrap">
                            <div class="member-picture">
                                <img src="assets/img/team/12.jpg" alt="TeamMember">
                                <div class="social-icons">
                                    <a routerLink='/team'>
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                    <a routerLink='/team'>
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                    <a routerLink='/team'>
                                        <i class="fab fa-behance"></i>
                                    </a>
                                    <a routerLink='/team'>
                                        <i class="fab fa-instagram"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="member-desc">
                            <h3 class="name"><a routerLink='/team-detail'>Morgan Graham</a></h3>
                            <span class="pro">Business Developer</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6 col-tiny-12">
                    <div class="team-member">
                        <div class="member-picture-wrap">
                            <div class="member-picture">
                                <img src="assets/img/team/13.jpg" alt="TeamMember">
                                <div class="social-icons">
                                    <a routerLink='/team'>
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                    <a routerLink='/team'>
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                    <a routerLink='/team'>
                                        <i class="fab fa-behance"></i>
                                    </a>
                                    <a routerLink='/team'>
                                        <i class="fab fa-instagram"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="member-desc">
                            <h3 class="name"><a routerLink='/team-detail'>Mason Parkinson</a></h3>
                            <span class="pro">Business Developer</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Team Section End ======-->

    <!--====== Video Start ======-->
    <section class="video-section bg-img-c section-gap" style="background-image: url(assets/img/video-bg/video-1.jpg);">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-xl-7 col-lg-8 col-md-10 order-2 order-md-1">
                    <div class="video-text">
                        <div class="section-title left-border mb-30">
                            <span class="title-tag">Watch Videos</span>
                            <h2 class="title">
                                Exclusive Video Presentation <br> About Recent Project
                            </h2>
                        </div>
                        <p>
                            Ranim ad minima veniam, quis nostrum exercitationem ullam corpsuscipit
                            laboriosam, nisi ut aliquid exea commodi consequa turuis autem
                        </p>
                        <a routerLink='/team' class="main-btn">Need Any Project</a>
                    </div>
                </div>
                <div class="col-lg-3 col-lg-4 col-md-2 order-1 order-md-2">
                    <div class="video-btn text-md-center">
                        <a routerLink='/team' class="play-btn popup-video">
                            <img src="assets/img/icons/play.svg" alt="">
                            <i class="fas fa-play"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="line-shape">
            <img src="assets/img/lines/08.png" alt="Line">
        </div>
    </section>
    <!--====== Video end ======-->

    <!--====== Skill Section Start ======-->
    <section class="skill-section section-gap-top">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-6 col-md-10">
                    <!-- Skill Text Block -->
                    <div class="skill-text">
                        <div class="section-title mb-40 left-border">
                            <span class="title-tag">Company Skills</span>
                            <h2 class="title">We Have Experience <br> For Business Planning</h2>
                        </div>
                        <p>
                            Sedut perspiciatis unde omnis iste natus error sit voluptat em accusantium doloremque
                            laudantium, totam raperiaeaque ipsa quae ab illo inventore veritatis et quasi
                        </p>
                        <p>
                            But I must explain to you how all this mistaken idenouncing pleasure and praising pain was
                            born and I will give completey account of the system, and expound the actual teachings of
                            the great explorer of the truth, the master-builder of human happiness one rejects,
                            dislikes, or avoid
                        </p>

                        <a routerLink='/about-us' class="main-btn">Learn More</a>
                    </div>
                </div>
                <div class="col-lg-6 col-md-10">
                    <div class="piechart-boxes">
                        <div class="chart-box">
                            <div class="chart" data-percent="25">
                                <i class="flaticon-presentation"></i>
                            </div>
                            <h4 class="title">Business Strategy</h4>
                        </div>
                        <div class="chart-box">
                            <div class="chart" data-percent="40">
                                <i class="flaticon-money-bags"></i>
                            </div>
                            <h4 class="title">Financial Planing</h4>
                        </div>
                        <div class="chart-box">
                            <div class="chart" data-percent="75">
                                <i class="flaticon-invest"></i>
                            </div>
                            <h4 class="title">Marketing Startegy</h4>
                        </div>
                        <div class="chart-box">
                            <div class="chart" data-percent="80">
                                <i class="flaticon-connector"></i>
                            </div>
                            <h4 class="title">Relationship Buildup</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Skill Section End ======-->

    <!--====== Client Area Start ======-->
    <section class="client-section">
        <div class="container">
            <div class="client-slider section-gap line-top">
                <div class="row align-items-center justify-content-between" id="clientSlider">
                    <div class="col">
                        <a routerLink='/team' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/team' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>