<app-header1></app-header1>
<!--====== Breadcrumb part Start ======-->
    <section class="breadcrumb-section bg-img-c" style="background-image: url(assets/img/breadcrumb.jpg);">
        <div class="container">
            <div class="breadcrumb-text">
                <h1 class="page-title">Our Services</h1>
                <ul>
                    <li><a routerLink='/'>Home</a></li>
                    <li>Services</li>
                </ul>
            </div>
        </div>
        <div class="breadcrumb-shapes">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
        </div>
    </section>
    <!--====== Breadcrumb part End ======-->

    <!--====== Working Process Part Start ======-->
    <section class="working-process-section">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-6 col-md-10 order-lg-1 order-2">
                    <div class="process-text">
                        <!-- Section Title -->
                        <div class="section-title left-border mb-30">
                            <span class="title-tag">Working Process</span>
                            <h2 class="title">How Dose We Works</h2>
                        </div>
                        <p>
                            Sedut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                            laudantium
                        </p>
                        <!-- process-loop -->
                        <div class="process-loop">
                            <div class="single-process wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                                <div class="icon">
                                    <i class="fal fa-coffee"></i>
                                    <span>01</span>
                                </div>
                                <div class="content">
                                    <h4>Have A Coffee</h4>
                                    <p>Doloremque laudantium totam raperiaeaqu ipsa quae ab illo inventore veritatis et
                                        quasi</p>
                                </div>
                            </div>
                            <div class="single-process wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                                <div class="icon">
                                    <i class="fal fa-coffee"></i>
                                    <span>02</span>
                                </div>
                                <div class="content">
                                    <h4>Meet With Advisors</h4>
                                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                                        doloremque</p>
                                </div>
                            </div>
                            <div class="single-process wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="800ms">
                                <div class="icon">
                                    <i class="fal fa-coffee"></i>
                                    <span>03</span>
                                </div>
                                <div class="content">
                                    <h4>Achieve Your Goals</h4>
                                    <p>Quis autem vel eum iure reprehenderit qui ieas voluptate velit esse quam nihil
                                        mole</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-10 order-lg-2 order-1">
                    <div class="process-video bg-img-c" style="background-image: url(assets/img/video-bg/01.jpg);">
                        <div class="video bg-img-c wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="400ms"
                            style="background-image: url(assets/img/video-bg/02.jpg);">
                            <a class="paly-icon popup-video" href="https://www.youtube.com/watch?v=fEErySYqItI">
                                <i class="fas fa-play"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="working-circle"></div>
    </section>
    <!--====== Working Process Part End ======-->

    <!--====== Service Section Start ======-->
    <section class="service-section grey-bg service-line-shape section-gap">
        <div class="container">
            <!-- Section Title -->
            <div class="section-title text-center both-border mb-50">
                <span class="title-tag">Most Features</span>
                <h2 class="title">We Provide Most Exclusive <br> Service For Business</h2>
            </div>
            <!-- Services Boxes -->
            <div class="row service-boxes justify-content-center">
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                    <div class="service-box-three border-0">
                        <div class="icon">
                            <img src="assets/img/icons/01.png" alt="Icon">
                        </div>
                        <h3><a routerLink='/service-detail'>Creative Idea</a></h3>
                        <p>Sed perspicia unde omnis</p>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="500ms">
                    <div class="service-box-three border-0">
                        <div class="icon">
                            <img src="assets/img/icons/02.png" alt="Icon">
                        </div>
                        <h3><a routerLink='/service-detail'>Business Strategy</a></h3>
                        <p>Quis autem velrepres hend</p>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                    <div class="service-box-three border-0">
                        <div class="icon">
                            <img src="assets/img/icons/03.png" alt="Icon">
                        </div>
                        <h3><a routerLink='/service-detail'>Relationship</a></h3>
                        <p>Sed perspicia unde omnis</p>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="700ms">
                    <div class="service-box-three border-0">
                        <div class="icon">
                            <img src="assets/img/icons/04.png" alt="Icon">
                        </div>
                        <h3><a routerLink='/service-detail'>Productivity</a></h3>
                        <p>Quis autem velrepres hend</p>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="800ms">
                    <div class="service-box-three border-0">
                        <div class="icon">
                            <img src="assets/img/icons/01.png" alt="Icon">
                        </div>
                        <h3><a routerLink='/service-detail'>Creative Idea</a></h3>
                        <p>Sed perspicia unde omnis</p>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="900ms">
                    <div class="service-box-three border-0">
                        <div class="icon">
                            <img src="assets/img/icons/02.png" alt="Icon">
                        </div>
                        <h3><a routerLink='/service-detail'>Business Strategy</a></h3>
                        <p>Quis autem velrepres hend</p>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="1s">
                    <div class="service-box-three border-0">
                        <div class="icon">
                            <img src="assets/img/icons/03.png" alt="Icon">
                        </div>
                        <h3><a routerLink='/service-detail'>Relationship</a></h3>
                        <p>Sed perspicia unde omnis</p>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="1.1s">
                    <div class="service-box-three border-0">
                        <div class="icon">
                            <img src="assets/img/icons/04.png" alt="Icon">
                        </div>
                        <h3><a routerLink='/service-detail'>Productivity</a></h3>
                        <p>Quis autem velrepres hend</p>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="line-one">
            <img src="assets/img/lines/12.png" alt="line-shape">
        </div>
        <div class="line-two">
            <img src="assets/img/lines/11.png" alt="line-shape">
        </div>
    </section>
    <!--====== Service Section End ======-->

    <!--====== Advanced Tabs Section Start ======-->
    <section class="advanced-tab section-gap">
        <div class="container">
            <!-- Tabs Buttons -->
            <div class="tab-buttons">
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <a class="active" id="nav-mission" data-toggle="tab" href="#tab-mission" role="tab">Our Mission &
                        Vision</a>
                    <a id="nav-history" data-toggle="tab" href="#tab-history" role="tab">Company History</a>
                    <a id="nav-business" data-toggle="tab" href="#tab-business" role="tab">Business Goals</a>
                    <a id="nav-team" data-toggle="tab" href="#tab-team" role="tab">Team Member</a>
                </div>
                <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="tab-mission" role="tabpanel">
                        <div class="tab-text-block left-image with-left-circle">
                            <div class="row align-items-center justify-content-center">
                                <div class="col-lg-6 col-md-10">
                                    <div class="block-image">
                                        <img src="assets/img/tab-block.jpg" alt="Image">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-10">
                                    <div class="block-text">
                                        <h2 class="title">Professional Business Guidance Agency</h2>
                                        <p>
                                            Sedut perspiciatis unde omnis iste natus error sit voluptat em accusantium
                                            doloremque laudantium, totam raperiaeaque ipsa quae ab illo inventore
                                            veritatis et quasi
                                        </p>
                                        <ul>
                                            <li>
                                                <i class="fas fa-check"></i>
                                                There are many variations of passages of LoreIpsum available, but the
                                                majority have suffered
                                            </li>
                                            <li>
                                                <i class="fas fa-check"></i>
                                                It uses a dictionary of over 200 Latin wor combined with a handful of
                                                model sentence structure
                                            </li>
                                            <li>
                                                <i class="fas fa-check"></i>
                                                Richard McClintock, a Latin profe hampden-sydney College in Virginia,
                                                looked up one more
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="tab-history" role="tabpanel">
                        <div class="tab-text-block right-image with-right-circle">
                            <div class="row align-items-center justify-content-center">
                                <div class="col-lg-6 col-md-10 order-2 order-lg-1">
                                    <div class="block-text">
                                        <h2 class="title">Professional Business Guidance Agency</h2>
                                        <p>
                                            Sedut perspiciatis unde omnis iste natus error sit voluptat em accusantium
                                            doloremque laudantium, totam raperiaeaque ipsa quae ab illo inventore
                                            veritatis et quasi
                                        </p>
                                        <ul>
                                            <li>
                                                <i class="fas fa-check"></i>
                                                There are many variations of passages of LoreIpsum available, but the
                                                majority have suffered
                                            </li>
                                            <li>
                                                <i class="fas fa-check"></i>
                                                It uses a dictionary of over 200 Latin wor combined with a handful of
                                                model sentence structure
                                            </li>
                                            <li>
                                                <i class="fas fa-check"></i>
                                                Richard McClintock, a Latin profe hampden-sydney College in Virginia,
                                                looked up one more
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-10 order-1 order-lg-2">
                                    <div class="block-image">
                                        <img src="assets/img/tab-block.jpg" alt="Image">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="tab-business" role="tabpanel">
                        <div class="tab-text-block left-image with-left-circle">
                            <div class="row align-items-center justify-content-center">
                                <div class="col-lg-6 col-md-10">
                                    <div class="block-image">
                                        <img src="assets/img/tab-block.jpg" alt="Image">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-10">
                                    <div class="block-text">
                                        <h2 class="title">Professional Business Guidance Agency</h2>
                                        <p>
                                            Sedut perspiciatis unde omnis iste natus error sit voluptat em accusantium
                                            doloremque laudantium, totam raperiaeaque ipsa quae ab illo inventore
                                            veritatis et quasi
                                        </p>
                                        <ul>
                                            <li>
                                                <i class="fas fa-check"></i>
                                                There are many variations of passages of LoreIpsum available, but the
                                                majority have suffered
                                            </li>
                                            <li>
                                                <i class="fas fa-check"></i>
                                                It uses a dictionary of over 200 Latin wor combined with a handful of
                                                model sentence structure
                                            </li>
                                            <li>
                                                <i class="fas fa-check"></i>
                                                Richard McClintock, a Latin profe hampden-sydney College in Virginia,
                                                looked up one more
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="tab-team" role="tabpanel">
                        <div class="tab-text-block right-image with-right-circle">
                            <div class="row align-items-center justify-content-center">
                                <div class="col-lg-6 col-md-10 order-2 order-lg-1">
                                    <div class="block-text">
                                        <h2 class="title">Professional Business Guidance Agency</h2>
                                        <p>
                                            Sedut perspiciatis unde omnis iste natus error sit voluptat em accusantium
                                            doloremque laudantium, totam raperiaeaque ipsa quae ab illo inventore
                                            veritatis et quasi
                                        </p>
                                        <ul>
                                            <li>
                                                <i class="fas fa-check"></i>
                                                There are many variations of passages of LoreIpsum available, but the
                                                majority have suffered
                                            </li>
                                            <li>
                                                <i class="fas fa-check"></i>
                                                It uses a dictionary of over 200 Latin wor combined with a handful of
                                                model sentence structure
                                            </li>
                                            <li>
                                                <i class="fas fa-check"></i>
                                                Richard McClintock, a Latin profe hampden-sydney College in Virginia,
                                                looked up one more
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-10 order-1 order-lg-2">
                                    <div class="block-image">
                                        <img src="assets/img/tab-block.jpg" alt="Image">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Advanced Tabs Section End ======-->

    <!--====== Video Start ======-->
    <section class="video-section-two bg-img-c" style="background-image: url(assets/img/video-bg/video-2.jpg);">
        <div class="container">
            <div class="row align-content-center justify-content-center">
                <div class="col-lg-10">
                    <div class="video-cont text-center">
                        <a href="https://www.youtube.com/watch?v=fEErySYqItI" class="play-btn popup-video"><i
                                class="fas fa-play"></i></a>
                        <h2>
                            Watch Our Latest Videos For Better Innovative Business
                        </h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="line-shape-one">
            <img src="assets/img/lines/12.png" alt="Line">
        </div>
        <div class="line-shape-two">
            <img src="assets/img/lines/11.png" alt="Line">
        </div>
    </section>
    <!--====== Video end ======-->

    <!--====== CTA Start ======-->
    <section class="cta-aection">
        <div class="container">
            <div class="cta-wrap mt-negative bg-img-c" style="background-image: url(assets/img/lines/16.png);">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <div class="cta-content text-center">
                            <div class="section-title both-border mb-30">
                                <span class="title-tag">Get A Quote</span>
                                <h2 class="title">Feel Any Project For Business Consulting Get Started Us</h2>
                            </div>
                            <a routerLink='/contact-us' class="main-btn main-btn-3">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== CTA Start ======-->

    <!--====== Client Area Start ======-->
    <section class="client-section">
        <div class="container">
            <div class="client-slider section-gap line-top">
                <div class="row align-items-center justify-content-between" id="clientSlider">
                    <div class="col">
                        <a routerLink='/service-one' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-one' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-one' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-one' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-one' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-one' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-one' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-one' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-one' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/service-one' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>