<app-header2></app-header2>
<!--====== Banner part start ======-->
    <section class="banner-section banner-section-two">
        <div class="banner-slider" id="bannerSlider">
            <div class="single-banner" style="background-image: url(assets/img/banner/04.jpg);">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-10">
                            <div class="banner-content text-center">
                                <h1 data-animation="fadeInDown" data-delay="0.8s">Take Great Idea To <br> Growth Your
                                    Business</h1>
                                <p data-animation="fadeInUp" data-delay="1s">
                                    Sedut perspiciatis unde omnis iste natus error sit voluptatem accusantium <br>
                                    doloremque
                                    laudanti totam raperiaeaque ipsa quaeab
                                </p>
                                <ul class="btn-wrap">
                                    <li data-animation="fadeInLeft" data-delay="1.2s">
                                        <a routerLink='/about-us' class="main-btn main-btn-4">Get Started Now</a>
                                    </li>
                                    <li data-animation="fadeInRight" data-delay="1.4s">
                                        <a routerLink='/service-one' class="main-btn main-btn-2">Our Services</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="single-banner" style="background-image: url(assets/img/banner/03.jpg);">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-10">
                            <div class="banner-content text-center">
                                <h1 data-animation="fadeInDown" data-delay="0.8s">Take Great Idea To <br> Growth Your
                                    Business</h1>
                                <p data-animation="fadeInUp" data-delay="1s">
                                    Sedut perspiciatis unde omnis iste natus error sit voluptatem accusantium <br>
                                    doloremque
                                    laudanti totam raperiaeaque ipsa quaeab
                                </p>
                                <ul class="btn-wrap">
                                    <li data-animation="fadeInLeft" data-delay="1.2s">
                                        <a routerLink='/about-us' class="main-btn main-btn-4">Get Started Now</a>
                                    </li>
                                    <li data-animation="fadeInRight" data-delay="1.4s">
                                        <a routerLink='/service-one' class="main-btn main-btn-2">Our Services</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="single-banner" style="background-image: url(assets/img/banner/02.jpg);">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-10">
                            <div class="banner-content text-center">
                                <h1 data-animation="fadeInDown" data-delay="0.8s">Take Great Idea To <br> Growth Your
                                    Business</h1>
                                <p data-animation="fadeInUp" data-delay="1s">
                                    Sedut perspiciatis unde omnis iste natus error sit voluptatem accusantium <br>
                                    doloremque
                                    laudanti totam raperiaeaque ipsa quaeab
                                </p>
                                <ul class="btn-wrap">
                                    <li data-animation="fadeInLeft" data-delay="1.2s">
                                        <a routerLink='/about-us' class="main-btn main-btn-4">Get Started Now</a>
                                    </li>
                                    <li data-animation="fadeInRight" data-delay="1.4s">
                                        <a routerLink='/service-one' class="main-btn main-btn-2">Our Services</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Banner part end ======-->

    <!--====== About Part start ======-->
    <section class="about-section section-gap">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-10">
                    <div class="about-video">
                        <div class="video-poster-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms"
                            style="background-image: url(assets/img/video-bg/poster-1.jpg);">
                        </div>
                        <div class="video-poster-two wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms"
                            style="background-image: url(assets/img/video-bg/poster-2.jpg);">
                            <a href="https://www.youtube.com/watch?v=fEErySYqItI" class="video-play-icon popup-video">
                                <i class="fas fa-play"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-10">
                    <div class="about-text-two">
                        <div class="section-title left-border mb-40">
                            <span class="title-tag">About Us</span>
                            <h2 class="title">Professional Business Guidance Agency </h2>
                        </div>

                        <p>
                            Sedut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                            laudantium, totam raperiaeaque ipsa quae ab illo inventore veritatis et quasi
                        </p>

                        <ul class="about-list">
                            <li> <i class="fas fa-check"></i> There are many variations of passages of LoreIpsum
                                available, but the majority have suffered</li>
                            <li> <i class="fas fa-check"></i> It uses a dictionary of over 200 Latin wor combined with a
                                handful of model sentence structure</li>
                            <li> <i class="fas fa-check"></i> Richard McClintock, a Latin profe hampden-sydney College
                                in Virginia, looked up one more</li>
                        </ul>
                        <a routerLink='/about-us' class="main-btn">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== About Part end ======-->

    <!--====== Service Part Start ======-->
    <section class="service-section shape-style-two service-line-shape section-gap grey-bg">
        <div class="container">
            <!-- Section Title -->
            <div class="section-title text-center both-border mb-50">
                <span class="title-tag">Comany Services</span>
                <h2 class="title">We Provide Most Exclusive <br> Service For Business</h2>
            </div>
            <!-- Services Boxes -->
            <div class="row service-boxes justify-content-center">
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                    <div class="service-box-two text-center">
                        <div class="icon">
                            <i class="flaticon-chart"></i>
                        </div>
                        <h3><a routerLink='/service-detail'>Creative Idea</a></h3>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                    <div class="service-box-two text-center">
                        <div class="icon">
                            <i class="flaticon-graph-1"></i>
                        </div>
                        <h3><a routerLink='/service-detail'>Marketing Strategy</a></h3>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="500ms">
                    <div class="service-box-two text-center">
                        <div class="icon">
                            <i class="flaticon-diagram"></i>
                        </div>
                        <h3><a routerLink='/service-detail'>UX/UI Strategy</a></h3>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                    <div class="service-box-two text-center">
                        <div class="icon">
                            <i class="flaticon-money"></i>
                        </div>
                        <h3><a routerLink='/service-detail'>Financial Plan</a></h3>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="700ms">
                    <div class="service-box-two text-center">
                        <div class="icon">
                            <i class="flaticon-diamond"></i>
                        </div>
                        <h3><a routerLink='/service-detail'>Design Strategy</a></h3>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="800ms">
                    <div class="service-box-two text-center">
                        <div class="icon">
                            <i class="flaticon-teamwork-1"></i>
                        </div>
                        <h3><a routerLink='/service-detail'>Relationship</a></h3>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="900ms">
                    <div class="service-box-two text-center">
                        <div class="icon">
                            <i class="flaticon-setup"></i>
                        </div>
                        <h3><a routerLink='/service-detail'>Market Analysis</a></h3>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="1000ms">
                    <div class="service-box-two text-center">
                        <div class="icon">
                            <i class="flaticon-pie-chart"></i>
                        </div>
                        <h3><a routerLink='/service-detail'>Online Support</a></h3>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="line-one">
            <img src="assets/img/lines/12.png" alt="line-shape">
        </div>
        <div class="line-two">
            <img src="assets/img/lines/11.png" alt="line-shape">
        </div>
    </section>
    <!--====== Service Part End ======-->

    <!--====== Project section Start ======-->
    <section class="project-section">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-8">
                    <!-- Section Title -->
                    <div class="section-title left-border">
                        <span class="title-tag">Latest Project</span>
                        <h2 class="title">We Complate Much More Latest Project</h2>
                    </div>
                </div>
                <div class="col-lg-5 col-md-4">
                    <div class="view-moore-btn text-md-right mt-30 mt-md-0">
                        <a routerLink='/portfolio-one' class="main-btn">View More Project</a>
                    </div>
                </div>
            </div>

            <!-- Project Boxes -->
            <div class="row project-boxes mt-80 justify-content-center">
                <div class="col-lg-4 col-sm-6">
                    <div class="project-box">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(assets/img/project/01.jpg);"></div>
                        </div>
                        <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>Free Consulting</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="project-box">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(assets/img/project/02.jpg);"></div>
                        </div>
                        <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>How To Business</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="project-box">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(assets/img/project/03.jpg);"></div>
                        </div>
                        <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>Design Strategy</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="project-box">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(assets/img/project/04.jpg);"></div>
                        </div>
                        <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>Research Strategy</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="project-box">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(assets/img/project/05.jpg);"></div>
                        </div>
                        <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>IT Consultations</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="project-box">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(assets/img/project/06.jpg);"></div>
                        </div>
                        <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>Business Monitor</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Project section End ======-->

    <!--====== Fact Part Start ======-->
    <section class="fact-section-two">
        <div class="container">
            <div class="fact-two-inner">
                <div class="fact-boxes row justify-content-between align-items-center">
                    <div class="col-lg-3 col-6">
                        <div class="fact-box fact-box-two text-center mb-40">
                            <div class="icon">
                                <i class="flaticon-graphic"></i>
                            </div>
                            <h2 class="counter">3568</h2>
                            <p class="title">Project Compalte</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="fact-box fact-box-two text-center mb-40">
                            <div class="icon">
                                <i class="flaticon-plan"></i>
                            </div>
                            <h2 class="counter">7859</h2>
                            <p class="title">Satisfied Clients</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="fact-box fact-box-two text-center mb-40">
                            <div class="icon">
                                <i class="flaticon-target-1"></i>
                            </div>
                            <h2 class="counter">6352</h2>
                            <p class="title">International Awards</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6">
                        <div class="fact-box fact-box-two text-center mb-40">
                            <div class="icon">
                                <i class="flaticon-teamwork"></i>
                            </div>
                            <h2 class="counter">7856</h2>
                            <p class="title">Expert Team Members</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Fact Part End ======-->

    <!--====== Working Process Part Start ======-->
    <section class="working-process-section grey-bg">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-6 col-md-10 order-lg-1 order-2">
                    <div class="process-text">
                        <!-- Section Title -->
                        <div class="section-title left-border mb-30">
                            <span class="title-tag">Working Process</span>
                            <h2 class="title">How Dose We Works</h2>
                        </div>
                        <p>
                            Sedut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                            laudantium
                        </p>
                        <!-- process-loop -->
                        <div class="process-loop">
                            <div class="single-process wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                                <div class="icon">
                                    <i class="fal fa-coffee"></i>
                                    <span>01</span>
                                </div>
                                <div class="content">
                                    <h4>Have A Coffee</h4>
                                    <p>Doloremque laudantium totam raperiaeaqu ipsa quae ab illo inventore veritatis et
                                        quasi</p>
                                </div>
                            </div>
                            <div class="single-process wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                                <div class="icon">
                                    <i class="fal fa-coffee"></i>
                                    <span>02</span>
                                </div>
                                <div class="content">
                                    <h4>Meet With Advisors</h4>
                                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                                        doloremque</p>
                                </div>
                            </div>
                            <div class="single-process wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="800ms">
                                <div class="icon">
                                    <i class="fal fa-coffee"></i>
                                    <span>03</span>
                                </div>
                                <div class="content">
                                    <h4>Achieve Your Goals</h4>
                                    <p>Quis autem vel eum iure reprehenderit qui ieas voluptate velit esse quam nihil
                                        mole</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-10 order-lg-2 order-1">
                    <div class="process-video bg-img-c" style="background-image: url(assets/img/video-bg/01.jpg);">
                        <div class="video bg-img-c wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="400ms"
                            style="background-image: url(assets/img/video-bg/02.jpg);">
                            <a class="paly-icon popup-video" href="https://www.youtube.com/watch?v=fEErySYqItI">
                                <i class="fas fa-play"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="working-circle"></div>
    </section>
    <!--====== Working Process Part End ======-->

    <!--====== Testimonials part start ======-->
    <section class="testimonial-section-two section-gap">
        <div class="container">
            <div class="row justify-content-center no-gutters">
                <div class="col-lg-9">
                    <div class="section-title both-border text-center mb-80">
                        <span class="title-tag">Meet Our Advisors</span>
                        <h2 class="title">We Have An Exclusive<br>Team Member</h2>
                    </div>
                    <div class="testimonial-items" id="testimonialSliderTwo">
                        <div class="testimonial-item" data-thumb="assets/img/testimonial/author.png">
                            <div class="author-img">
                                <img src="assets/img/testimonial/author-8.jpg" alt="Image">
                            </div>
                            <div class="content">
                                <p>
                                    <span class="quote-top">
                                        <i class="fas fa-quote-left"></i>
                                    </span>
                                    Sedut perspiciatis unde omniiste natus errorsit voluptatem accusant doloremque
                                    laudantium, totam rem aperiam, eaque ipsa quae ab ilinventore veritatis et quasi
                                    architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                                    voluptas sit aspernatur aut
                                    <span class="quote-bottom">
                                        <i class="fas fa-quote-right"></i>
                                    </span>
                                </p>
                            </div>
                            <div class="author-name">
                                <h4>Richard E. Harrisona</h4>
                                <span>CEO & Founder</span>
                            </div>
                        </div>
                        <div class="testimonial-item" data-thumb="assets/img/testimonial/author-3.png">
                            <div class="author-img">
                                <img src="assets/img/testimonial/author-8.jpg" alt="Image">
                            </div>
                            <div class="content">
                                <p>
                                    <span class="quote-top">
                                        <i class="fas fa-quote-left"></i>
                                    </span>
                                    Sedut perspiciatis unde omniiste natus errorsit voluptatem accusant doloremque
                                    laudantium, totam rem aperiam, eaque ipsa quae ab ilinventore veritatis et quasi
                                    architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                                    voluptas sit aspernatur aut
                                    <span class="quote-bottom">
                                        <i class="fas fa-quote-right"></i>
                                    </span>
                                </p>
                            </div>
                            <div class="author-name">
                                <h4>Richard E. Harrisona</h4>
                                <span>CEO & Founder</span>
                            </div>
                        </div>
                        <div class="testimonial-item" data-thumb="assets/img/testimonial/author-4.png">
                            <div class="author-img">
                                <img src="assets/img/testimonial/author-8.jpg" alt="Image">
                            </div>
                            <div class="content">
                                <p>
                                    <span class="quote-top">
                                        <i class="fas fa-quote-left"></i>
                                    </span>
                                    Sedut perspiciatis unde omniiste natus errorsit voluptatem accusant doloremque
                                    laudantium, totam rem aperiam, eaque ipsa quae ab ilinventore veritatis et quasi
                                    architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                                    voluptas sit aspernatur aut
                                    <span class="quote-bottom">
                                        <i class="fas fa-quote-right"></i>
                                    </span>
                                </p>
                            </div>
                            <div class="author-name">
                                <h4>Richard E. Harrisona</h4>
                                <span>CEO & Founder</span>
                            </div>
                        </div>
                        <div class="testimonial-item" data-thumb="assets/img/testimonial/author-5.png">
                            <div class="author-img">
                                <img src="assets/img/testimonial/author-8.jpg" alt="Image">
                            </div>
                            <div class="content">
                                <p>
                                    <span class="quote-top">
                                        <i class="fas fa-quote-left"></i>
                                    </span>
                                    Sedut perspiciatis unde omniiste natus errorsit voluptatem accusant doloremque
                                    laudantium, totam rem aperiam, eaque ipsa quae ab ilinventore veritatis et quasi
                                    architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                                    voluptas sit aspernatur aut
                                    <span class="quote-bottom">
                                        <i class="fas fa-quote-right"></i>
                                    </span>
                                </p>
                            </div>
                            <div class="author-name">
                                <h4>Richard E. Harrisona</h4>
                                <span>CEO & Founder</span>
                            </div>
                        </div>
                        <div class="testimonial-item" data-thumb="assets/img/testimonial/author-6.png">
                            <div class="author-img">
                                <img src="assets/img/testimonial/author-8.jpg" alt="Image">
                            </div>
                            <div class="content">
                                <p>
                                    <span class="quote-top">
                                        <i class="fas fa-quote-left"></i>
                                    </span>
                                    Sedut perspiciatis unde omniiste natus errorsit voluptatem accusant doloremque
                                    laudantium, totam rem aperiam, eaque ipsa quae ab ilinventore veritatis et quasi
                                    architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                                    voluptas sit aspernatur aut
                                    <span class="quote-bottom">
                                        <i class="fas fa-quote-right"></i>
                                    </span>
                                </p>
                            </div>
                            <div class="author-name">
                                <h4>Richard E. Harrisona</h4>
                                <span>CEO & Founder</span>
                            </div>
                        </div>
                    </div>
                    <div class="testimonial-dots"></div>
                </div>
            </div>
        </div>
        <div class="testimonial-quote-icon">
            <img src="assets/img/icons/quote.png" alt="quote">
        </div>
    </section>
    <!--====== Testimonials part end ======-->

    <!--====== Video Start ======-->
    <section class="video-section-two bg-img-c" style="background-image: url(assets/img/video-bg/video-2.jpg);">
        <div class="container">
            <div class="row align-content-center justify-content-center">
                <div class="col-lg-10">
                    <div class="video-cont text-center">
                        <a href="https://www.youtube.com/watch?v=fEErySYqItI"
                            class="play-btn popup-video wow fadeInDown" data-wow-duration="1500ms"
                            data-wow-delay="400ms"><i class="fas fa-play"></i></a>
                        <h2>
                            Watch Our Latest Videos For Better Innovative Business
                        </h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="line-shape-one">
            <img src="assets/img/lines/12.png" alt="Line">
        </div>
        <div class="line-shape-two">
            <img src="assets/img/lines/11.png" alt="Line">
        </div>
    </section>
    <!--====== Video end ======-->

    <!--====== Latest Post Start ======-->
    <section class="latest-post-section section-gap-top">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-8">
                    <div class="section-title left-border">
                        <span class="title-tag">Important Articles</span>
                        <h2 class="title">Something Know Our <br> Latest News & Blog</h2>
                    </div>
                </div>
                <div class="col-lg-6 col-md-4">
                    <div class="text-md-right mt-30 mt-md-0">
                        <a routerLink='/blog-grid' class="main-btn">View All News</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid container-1600">
            <div class="latest-post-loop loop-two">
                <div class="row" id="latestPostSlider">
                    <div class="col-lg-3 col-md-3">
                        <div class="latest-post-box-two">
                            <div class="post-thumb-wrap">
                                <div class="post-thumb bg-img-c"
                                    style="background-image: url(assets/img/latest-post/01.jpg);">
                                </div>
                                <span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span>
                            </div>
                            <div class="post-desc">
                                <h3 class="title">
                                    <a routerLink='/blog-detail'>
                                        How Performance Visiblety With GitLab CI & Hood
                                    </a>
                                </h3>
                                <a routerLink='/blog-detail' class="post-link">
                                    Learn More <i class="far fa-long-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                        <div class="latest-post-box-two">
                            <div class="post-thumb-wrap">
                                <div class="post-thumb bg-img-c"
                                    style="background-image: url(assets/img/latest-post/02.jpg);">
                                </div>
                                <span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span>
                            </div>
                            <div class="post-desc">
                                <h3 class="title">
                                    <a routerLink='/blog-detail'>
                                        Inspired Design Decisions With Max Huber Turne
                                    </a>
                                </h3>
                                <a routerLink='/blog-detail' class="post-link">
                                    Learn More <i class="far fa-long-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                        <div class="latest-post-box-two">
                            <div class="post-thumb-wrap">
                                <div class="post-thumb bg-img-c"
                                    style="background-image: url(assets/img/latest-post/03.jpg);">
                                </div>
                                <span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span>
                            </div>
                            <div class="post-desc">
                                <h3 class="title">
                                    <a routerLink='/blog-detail'>
                                        Understandin Machine An Open Standard For
                                    </a>
                                </h3>
                                <a routerLink='/blog-detail' class="post-link">
                                    Learn More <i class="far fa-long-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                        <div class="latest-post-box-two">
                            <div class="post-thumb-wrap">
                                <div class="post-thumb bg-img-c"
                                    style="background-image: url(assets/img/latest-post/04.jpg);">
                                </div>
                                <span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span>
                            </div>
                            <div class="post-desc">
                                <h3 class="title">
                                    <a routerLink='/blog-detail'>
                                        Inspired Design Decisionse With Max Turning Say
                                    </a>
                                </h3>
                                <a routerLink='/blog-detail' class="post-link">
                                    Learn More <i class="far fa-long-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                        <div class="latest-post-box-two">
                            <div class="post-thumb-wrap">
                                <div class="post-thumb bg-img-c"
                                    style="background-image: url(assets/img/latest-post/01.jpg);">
                                </div>
                                <span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span>
                            </div>
                            <div class="post-desc">
                                <h3 class="title">
                                    <a routerLink='/blog-detail'>
                                        How Performance Visiblety With GitLab CI & Hood
                                    </a>
                                </h3>
                                <a routerLink='/blog-detail' class="post-link">
                                    Learn More <i class="far fa-long-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                        <div class="latest-post-box-two">
                            <div class="post-thumb-wrap">
                                <div class="post-thumb bg-img-c"
                                    style="background-image: url(assets/img/latest-post/02.jpg);">
                                </div>
                                <span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span>
                            </div>
                            <div class="post-desc">
                                <h3 class="title">
                                    <a routerLink='/blog-detail'>
                                        Inspired Design Decisions With Max Huber Turne
                                    </a>
                                </h3>
                                <a routerLink='/blog-detail' class="post-link">
                                    Learn More <i class="far fa-long-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                        <div class="latest-post-box-two">
                            <div class="post-thumb-wrap">
                                <div class="post-thumb bg-img-c"
                                    style="background-image: url(assets/img/latest-post/03.jpg);">
                                </div>
                                <span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span>
                            </div>
                            <div class="post-desc">
                                <h3 class="title">
                                    <a routerLink='/blog-detail'>
                                        Understandin Machine An Open Standard For
                                    </a>
                                </h3>
                                <a routerLink='/blog-detail' class="post-link">
                                    Learn More <i class="far fa-long-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                        <div class="latest-post-box-two">
                            <div class="post-thumb-wrap">
                                <div class="post-thumb bg-img-c"
                                    style="background-image: url(assets/img/latest-post/04.jpg);">
                                </div>
                                <span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span>
                            </div>
                            <div class="post-desc">
                                <h3 class="title">
                                    <a routerLink='/blog-detail'>
                                        Inspired Design Decisionse With Max Turning Say
                                    </a>
                                </h3>
                                <a routerLink='/blog-detail' class="post-link">
                                    Learn More <i class="far fa-long-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Latest Post Start ======-->

    <!--====== Client Area Start ======-->
    <section class="client-section">
        <div class="container">
            <div class="client-slider section-gap">
                <div class="row align-items-center justify-content-between" id="clientSlider">
                    <div class="col">
                        <a routerLink='/homepage2' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage2' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage2' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage2' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage2' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage2' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage2' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage2' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage2' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage2' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Client Area End ======-->
    <app-footer2></app-footer2>