<div (click)="navegarTo()" (mouseover)="cambiarImagen()" (mouseout)="restaurarImagen()" class="project-box">
    <div class="project-thumb">
        <div  class="thumb bg-img-c" style="background-image: url(assets/img/cardsPictures/{{this.imagen}});"></div>
    </div>
    <div class="project-desc text-center">
        <h4><a routerLink='/{{url}}'>{{this.titulo}}</a></h4>
        <p>{{this.subtitulo}}</p>
        <a routerLink='/{{url}}' class="project-link">
            <i class="fal fa-long-arrow-right"></i>
        </a>
    </div>
</div>