<div class="shop-sidebar">
                        <div class="widget product-cat-widget">
                            <h4 class="widget-title">Category</h4>
                            <ul>
                                <li><a routerLink='/shop'>Accessories</a></li>
                                <li><a routerLink='/shop'>Clothing</a></li>
                                <li><a routerLink='/shop'>Decor</a></li>
                                <li><a routerLink='/shop'>Hoodies</a></li>
                                <li><a routerLink='/shop'>Music</a></li>
                                <li><a routerLink='/shop'>Tshirts</a></li>
                                <li><a routerLink='/shop'>Uncategorized</a></li>
                            </ul>
                        </div>
                        <div class="widget product-tag-widget">
                            <h4 class="widget-title">Populer Tag</h4>
                            <div class="tags-loop">
                                <a routerLink='/shop'>Cleaning</a>
                                <a routerLink='/shop'>Business</a>
                                <a routerLink='/shop'>Car</a>
                                <a routerLink='/shop'>House</a>
                                <a routerLink='/shop'>Wasing</a>
                                <a routerLink='/shop'>Agency</a>
                            </div>
                        </div>
                        <div class="widget product-filter-widget">
                            <h4 class="widget-title">Filer By Price</h4>
                            <div class="price-range-wrap">
                                <div class="slider-range">
                                    <div id="slider-range"></div>
                                </div>
                                <div class="price-ammount">
                                    <span>Price :</span>
                                    <input type="text" id="amount" name="price" disabled>
                                </div>
                            </div>
                        </div>
                    </div>